import utils from 'helpers/utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAuthTeamId, useAuthUserId} from 'services/auth/auth.utils';
import {useClientCustomFieldCache} from 'services/client/customField/customField.hooks';
import {useCollectionGet, useCollectionList} from 'services/collection/collection.hooks';
import constants from 'helpers/constants';
import {useTagList} from 'services/tag/tag.hooks';
import {
  useEntityBaseList,
  useEntityByName,
  useEntityByNameES,
  useEntityList,
  useEntitySearch,
  useEntitySearchCocNumber,
  useEntitySearchCreditSafeId,
  useEntitySearchGoogle,
  useEntityUpdate
} from 'services/entity/entity.hooks';
import {useMergeFieldData, useMergeFilterGroupDefinitions} from 'helpers/hooks/utils';
import {useSourceList} from 'services/source/source.hooks';
import {useClientCallbacks} from 'services/client/client.utils';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {Warning} from '@mui/icons-material';
import {useEntityPatentCpcSearch} from 'services/entity/patent/cpc/cpc.hooks';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';

export function processEntity (entity) {
  const tagGroups = (entity.tagsGroups || [])
    .map((tg) => utils.camelcaseEx(tg));
  const tagsObj = utils.camelcaseEx(entity.tagsObj);

  const getTags = (system, classification) => {
    return (tagsObj || [])
      .filter((t) => {
        return ((system && +t.ownerClientId === 0) || (!system && +t.ownerClientId > 0)) &&
          (!classification || constants.data.profileLabels.find((label) => {
            return label.labels.find((l) => l.value.toLowerCase() === t.value.toLowerCase());
          }));
      })
      .sort((a, b) => {
        return a.value.localeCompare(b.value) ? a.value.localeCompare(b.value) : +a.tagId - +b.tagId;
      });
  };

  entity = utils.camelcase(entity);

  return {
    ...entity,
    type: entity.type ?? entity.evalType,
    tags: getTags(false, false),
    systemTags: getTags(true, false),
    classificationTags: getTags(true, true),
    ...entity.custom?.reduce((o, cf) => {
      o[utils.camelcase(cf.name)] = cf.value
      return o;
    }, {}),
    tagGroups: tagGroups.map((tg) => ({
      groupId: tg.groupId,
      tags: tg.selectedTags
    })),
    hasPatents: +entity.patentCount > 0,
    location: utils.cleanObject(entity.location),
    collections: utils.camelcaseEx(entity.collections ?? []),
    sources: utils.camelcaseEx(entity.sources ?? []),
    feeds: utils.camelcaseEx(entity.feeds ?? []),
    clientQuestions: utils.camelcaseEx(entity.clientQuestions ?? []),
    collectionQuestions: utils.camelcaseEx(entity.collectionQuestions ?? []),
    questionnaireAnswers: utils.camelcaseEx(entity.questionnaireAnswers),
    questionnaireUserAnswers: utils.camelcaseEx(entity.questionnaireUserAnswers),
    ...(entity.links?.reduce((o, l) => {
      o[l.name] = l.value ?? null;
      return o;
    }, {})),
    fileUploads: utils.camelcaseEx(entity.fileUploads ?? [])
      .map((f) => ({...f, uploadId: f.fileUploadId}))
  }
}

export function processEntityPoints (entity, tagGroup, client) {
  const questions = client ? entity.clientQuestions : entity.collectionQuestions;
  return questions
    .filter((q) => {
      return q.questionType === constants.collection.questionTypes.advanced &&
        q.analyserType === constants.collection.analyserTypes.collectionTagAnalyser &&
        +q.tagGroup === +tagGroup.groupId;
    })
    .reduce((t, q) => t + +q.points, 0);
}

export function processEntityTagGroupEvidence (entity, field, value) {
  let evidences = [];

  let values = utils.toArray(value)
    .map((t) => field.tagGroup.tags.find((tgt) => +t?.tagId === +tgt.tagId || (t?.value ?? t) === tgt.value))
    .filter((_) => (_));
  let questions = (entity?.collectionQuestions ?? []).concat(entity?.clientQuestions ?? [])
    .filter((q) => {
      return q.questionType === constants.collection.questionTypes.basic &&
        q.analyserType === constants.collection.analyserTypes.collectionTagAnalyser &&
        +q.tagGroup === +field.tagGroup.groupId
    });

  if (utils.toArray(values).length > 0 && questions.length > 0) {
    evidences = evidences.concat(values.reduce((a, v) => {
      return a.concat(questions.reduce((a, q) => {
        if (!a.find((e) => +e.tag.tagId === +v.tagId)) {
          const sq = q.subQuestions.find((sq) => +sq.id === +v.tagId && sq.analyserType !== constants.collection.analyserTypes.justPointsAnalyser);

          const evidence = sq ? [sq.currentEvidence?.[2], sq.currentEvidence?.[1], sq.currentEvidence?.[3]].find((e) => +e?.score > 0) ||
            (sq.currentEvidence?.[2] ?? sq.currentEvidence?.[1] ?? sq.currentEvidence?.[3]) : null;

          if (evidence && evidence.fullExplanation) {
            a.push({
              tag: v,
              evidence
            });
          } else {
            a.push({
              tag: v,
              evidence: {
                fullExplanation: 'Manual selection'
              }
            });
          }
        }

        return a;
      }, []));
    }, []));
  }

  return evidences;
}

export function processEntityInfo (entity, field, value) {
  if (field.tagGroupId) {
    const evidence = processEntityTagGroupEvidence(entity, field, value);
    if (evidence.length > 0) {
      let value = '';
      evidence.forEach((e, idx) => {
        const explanation = e.evidence.fullExplanation;
        const addNewline = !(explanation.trim().startsWith('<p>') || explanation.trim().startsWith('<div>')); // p or div will become newline

        value += `${idx > 0 ? '  \n   \n' : ''}**${e.tag.value}**${addNewline ? '  \n' : ''}${explanation}`;
      });

      return <React.Fragment>
        <Markdown>{value}</Markdown>
      </React.Fragment>
    } else {
      return null;
    }
  } else {
    const lookup = entity?.availableSources?.lookup?.[field.name];

    if (lookup) {
      return <Markdown>{lookup}</Markdown>;
    } else {
      return null;
    }
  }
}

export function processEntityFeedback (entity, field, value) {
  let suggestion, explanation, tooltip, isLink, hasEnrichment

  if (field.link && ['website', 'linkedin'].includes(utils.camelcase(field.name))) {
    const enrichmentStats = entity?.enrichmentStats?.enrichment;
    hasEnrichment = !utils.isEmpty(enrichmentStats);

    if (enrichmentStats?.[`${field.link.value}Feedback`]) {
      isLink = true;
      tooltip = 'Verify link';

      if (field.link.value === 'website' && !enrichmentStats.websiteConfidence && enrichmentStats.linkedinWebsite &&
        utils.getHostname(entity.website) !== utils.getHostname(enrichmentStats.linkedinWebsite)) {
        if (utils.getHostname(value) !== utils.getHostname(enrichmentStats.linkedinWebsite) &&
          utils.getHostname(entity.website) === utils.getHostname(value)) {
          suggestion = enrichmentStats.linkedinWebsite;
        }
      } else if (!enrichmentStats[`${field.link.value}Confidence`]) {
        if (utils.isEmpty(entity[field.link.value])) {
          if (utils.isEmpty(value)) {
            explanation = 'Please enter the correct link';
          }
        } else {
          if ((field.link.value !== 'linkedin' && utils.getHostname(entity[field.link.value]) === utils.getHostname(value)) ||
            (field.link.value === 'linkedin' && utils.cleanLinkedInHandle(entity[field.link.value]) === utils.cleanLinkedInHandle(value))) {
            explanation = 'We could not match the this link';
          }
        }
      }
    }
  } else if (utils.camelcase(field.name) === 'cocNumber') {
    const enrichmentStats = entity?.enrichmentStats?.companyInfo;
    hasEnrichment = !utils.isEmpty(enrichmentStats);

    if (enrichmentStats?.['cocNumberFeedback']) {
      isLink = false;
      tooltip = 'Verify CoC number';

      if (!enrichmentStats['cocNumberConfidence']) {
        if (utils.isEmpty(entity.cocNumber)) {
          if (utils.isEmpty(value)) {
            explanation = 'Please enter the correct CoC number';
          }
        } else {
          if (entity.cocNumber === value) {
            explanation = 'We could not match this CoC number';
          }
        }
      }
    }
  } else if (utils.camelcase(field.name) === 'creditSafeId') {
    const enrichmentStats = entity?.enrichmentStats?.creditSafe;
    hasEnrichment = !utils.isEmpty(enrichmentStats);

    if (enrichmentStats?.['creditSafeIdFeedback']) {
      isLink = false;
      tooltip = 'Verify Creditsafe ID';

      if (!enrichmentStats['creditSafeIdConfidence']) {
        if (utils.isEmpty(entity.creditSafeId)) {
          if (utils.isEmpty(value)) {
            explanation = 'Please enter the correct Creditsafe ID';
          }
        } else {
          if (entity.creditSafeId === value) {
            explanation = 'We could not match this Creditsafe ID';
          }
        }
      }
    }
  }

  if (suggestion || explanation) {
    return {
      show: true,
      icon: Warning,
      isLink: isLink,
      tooltip: tooltip,
      suggestion: suggestion,
      explanation: explanation
    }
  } else if (hasEnrichment) {
    return {
      show: false,
      verified: true
    }
  }
}

export function useEntityCallbacks () {
  const clientCallbacks = useClientCallbacks();

  const [entitiesListSearch, setEntitiesListSearch] = useState({});
  const entitiesListCurrent = entitiesListSearch[Object.keys(entitiesListSearch)[0]];
  const entitiesListEnabled = Boolean(entitiesListCurrent?.callback);
  const entitiesList = useEntityByName({
    name: entitiesListCurrent?.search,
    filter: utils.addFilter((utils.toArray(entitiesListCurrent?.ids, true).length > 0) ? [{
      id: 'entityIds',
      value: utils.toArray(entitiesListCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, entitiesListCurrent?.filter),
    minimal: true,
    pageSize: Math.max(15, utils.toArray(entitiesListCurrent?.ids, true)?.length)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: entitiesListEnabled
  });

  const entitiesListError = !entitiesList.status.isBusy && +entitiesList.status?.error?.response?.status >= 400;
  const entitiesListESEnabled = Boolean(entitiesListCurrent?.callback) && entitiesListError;
  const entitiesListES = useEntityByNameES({
    name: entitiesListCurrent?.search,
    filter: utils.addFilter((utils.toArray(entitiesListCurrent?.ids, true).length > 0) ? [{
      id: 'entityIds',
      value: utils.toArray(entitiesListCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, entitiesListCurrent?.filter),
    minimal: true,
    pageSize: Math.max(15, utils.toArray(entitiesListCurrent?.ids, true)?.length)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: entitiesListESEnabled
  });

  const [tagListSearch, setTagListSearch] = useState({});
  const tagListCurrent = tagListSearch[Object.keys(tagListSearch)[0]];
  const tagListEnabled = Boolean(tagListCurrent?.callback);
  const tagList = useTagList({
    search: tagListCurrent?.search,
    filter: utils.addFilter((utils.toArray(tagListCurrent?.ids, true).length > 0) ? [{
      id: 'tagIds',
      value: utils.toArray(tagListCurrent?.ids, true)
        .filter((v) => !utils.isString(v) && utils.isInt(v))
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }].concat([{
      id: 'tagValues',
      value: utils.toArray(tagListCurrent?.ids, true)
        .filter((v) => utils.isString(v))
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }]).filter((f) => !utils.isEmpty(f.value)) : null, tagListCurrent?.filter),
    pageSize: Math.max(15, utils.toArray(tagListCurrent?.ids, true)?.length),
    minimal: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: tagListEnabled
  });

  const [cpcListSearch, setCpcListSearch] = useState({});
  const cpcListCurrent = cpcListSearch[Object.keys(cpcListSearch)[0]];
  const cpcListEnabled = Boolean(cpcListCurrent?.callback);
  const cpcList = useEntityPatentCpcSearch({
    search: cpcListCurrent?.search,
    filter: utils.addFilter((utils.toArray(cpcListCurrent?.ids, true).length > 0) ? [{
      id: 'cpcIds',
      value: utils.toArray(cpcListCurrent?.ids, true)
        .filter((v) => !utils.isString(v) && utils.isInt(v))
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }].concat([{
      id: 'symbols',
      value: utils.toArray(cpcListCurrent?.ids, true)
        .filter((v) => utils.isString(v))
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }]).filter((f) => !utils.isEmpty(f.value)) : null, cpcListCurrent?.filter),
    ipc: false,
    pageSize: Math.max(15, utils.toArray(cpcListCurrent?.ids, true)?.length)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: cpcListEnabled
  });

  const [ipcListSearch, setIpcListSearch] = useState({});
  const ipcListCurrent = ipcListSearch[Object.keys(ipcListSearch)[0]];
  const ipcListEnabled = Boolean(ipcListCurrent?.callback);
  const ipcList = useEntityPatentCpcSearch({
    search: ipcListCurrent?.search,
    filter: utils.addFilter((utils.toArray(ipcListCurrent?.ids, true).length > 0) ? [{
      id: 'cpcIds',
      value: utils.toArray(ipcListCurrent?.ids, true)
        .filter((v) => !utils.isString(v) && utils.isInt(v))
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }].concat([{
      id: 'symbols',
      value: utils.toArray(ipcListCurrent?.ids, true)
        .filter((v) => utils.isString(v))
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }]).filter((f) => !utils.isEmpty(f.value)) : null, ipcListCurrent?.filter),
    ipc: true,
    pageSize: Math.max(15, utils.toArray(ipcListCurrent?.ids, true)?.length)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: ipcListEnabled
  });

  const [collectionListSearch, setCollectionListSearch] = useState({});
  const collectionListCurrent = collectionListSearch[Object.keys(collectionListSearch)[0]];
  const collectionListEnabled = Boolean(collectionListCurrent?.callback);
  const collectionList = useCollectionList({
    search: collectionListCurrent?.search,
    filter: utils.addFilter((utils.toArray(collectionListCurrent?.ids, true).length > 0) ? [{
      id: 'collectionIds',
      value: utils.toArray(collectionListCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, collectionListCurrent?.filter),
    lookupOnly: true,
    allPages: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: collectionListEnabled
  });

  const [sourceListSearch, setSourceListSearch] = useState({});
  const sourceListCurrent = sourceListSearch[Object.keys(sourceListSearch)[0]];
  const sourceListEnabled = Boolean(sourceListCurrent?.callback);
  const sourceList = useSourceList({
    search: sourceListCurrent?.search,
    filter: utils.addFilter((utils.toArray(sourceListCurrent?.ids, true).length > 0) ? [{
      id: 'sourceIds',
      value: utils.toArray(sourceListCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, sourceListCurrent?.filter),
    pageSize: Math.max(100, utils.toArray(sourceListCurrent?.ids, true)?.length)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: sourceListEnabled
  });

  const [linkSuggestionSearch, setLinkSuggestionSearch] = useState({});
  const linkSuggestionCurrent = linkSuggestionSearch[Object.keys(linkSuggestionSearch)[0]];
  const linkSuggestionEnabled = Boolean(linkSuggestionCurrent?.callback) && linkSuggestionCurrent?.search?.length > 0 &&
    linkSuggestionCurrent?.link?.length > 0;
  const linkSuggestionList = useEntitySearchGoogle({
    query: linkSuggestionCurrent?.search,
    filter: linkSuggestionCurrent?.filter
  }, {
    ...constants.queryOptions.runOnce,
    enabled: linkSuggestionEnabled
  });
  const linkSuggestions = useLinkSuggestions(linkSuggestionList.data?.data, linkSuggestionCurrent?.name);

  const [cocNumberSuggestionSearch, setCocNumberSuggestionSearch] = useState({});
  const cocNumberSuggestionCurrent = cocNumberSuggestionSearch[Object.keys(cocNumberSuggestionSearch)[0]];
  const cocNumberSuggestionEnabled = Boolean(cocNumberSuggestionCurrent?.callback) && cocNumberSuggestionCurrent?.name?.length > 0;
  const cocNumberSuggestionList = useEntitySearchCocNumber({
    name: cocNumberSuggestionCurrent?.name,
    country: cocNumberSuggestionCurrent?.country
  }, {
    ...constants.queryOptions.runOnce,
    enabled: cocNumberSuggestionEnabled
  });

  const [creditSafeIdSuggestionSearch, setCreditSafeIdSuggestionSearch] = useState({});
  const creditSafeIdSuggestionCurrent = creditSafeIdSuggestionSearch[Object.keys(creditSafeIdSuggestionSearch)[0]];
  const creditSafeIdSuggestionEnabled = Boolean(creditSafeIdSuggestionCurrent?.callback) && creditSafeIdSuggestionCurrent?.name?.length > 0;
  const creditSafeIdSuggestionList = useEntitySearchCreditSafeId({
    name: creditSafeIdSuggestionCurrent?.name,
    country: creditSafeIdSuggestionCurrent?.country,
    website: creditSafeIdSuggestionCurrent?.website,
    city: creditSafeIdSuggestionCurrent?.city,
    address: creditSafeIdSuggestionCurrent?.address,
    cocNumber: creditSafeIdSuggestionCurrent?.cocNumber,
  }, {
    ...constants.queryOptions.runOnce,
    enabled: creditSafeIdSuggestionEnabled
  });

  useEffect(() => {
    if (!(entitiesListEnabled || entitiesListESEnabled) ||
        (entitiesListEnabled && (entitiesList.data || (entitiesList.status.hasError && entitiesListES.status.hasError))) ||
        (entitiesListESEnabled && (entitiesListES.data || (entitiesList.status.hasError && entitiesListES.status.hasError)))) {
      const entities = (entitiesList.data ?? []).concat(entitiesListES.data ?? []);
      entitiesListCurrent?.callback?.(entities.map((e) => ({
        label: e.name,
        value: +e.entityId,
        entity: e
      })));
      if (utils.isDefined(entitiesListCurrent)) {
        setEntitiesListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [entitiesListCurrent, entitiesListEnabled, entitiesListESEnabled, entitiesList.data, entitiesListES.data,
    entitiesList.status.hasError, entitiesListES.status.hasError]);

  useEffect(() => {
    if (!tagListEnabled || tagList.data || tagList.status.hasError) {
      tagListCurrent?.callback?.((tagList.data ?? []).map((t) => ({tag: t, label: t.value?.toLowerCase(), value: +t.tagId})));
      if (utils.isDefined(tagListCurrent)) {
        setTagListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [tagListCurrent, tagListEnabled, tagList.data, tagList.status.hasError]);

  useEffect(() => {
    if (!cpcListEnabled || cpcList.data || cpcList.status.hasError) {
      cpcListCurrent?.callback?.(utils.uniqueArray((cpcList.data ?? []).map((c) => {
        let value = c.symbol;
        const level = c.titles.reduce((a, c) => c.level > a ? c.level : a, 0);

        if (level < 8) { // for high classes include wildcards
          if (c.symbol.includes('/')) {
            value = c.symbol.split('/')[0] + '/*';
          } else {
            value = c.symbol + '*';
          }
        }

        return {cpc: c, label: c.symbol, value};
      }), 'value'));

      if (utils.isDefined(cpcListCurrent)) {
        setCpcListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [cpcListCurrent, cpcListEnabled, cpcList.data, cpcList.status.hasError]);

  useEffect(() => {
    if (!ipcListEnabled || ipcList.data || ipcList.status.hasError) {
      ipcListCurrent?.callback?.(utils.uniqueArray((ipcList.data ?? []).map((i) => {
        let value = i.symbol;
        const level = i.titles.reduce((a, c) => c.level > a ? c.level : a, 0);

        if (level < 8) { // for high classes include wildcards
          if (i.symbol.includes('/')) {
            value = i.symbol.split('/')[0] + '/*';
          } else {
            value = i.symbol + '*';
          }
        }

        return {ipc: i, label: i.symbol, value};
      }), 'value'));

      if (utils.isDefined(ipcListCurrent)) {
        setIpcListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [ipcListCurrent, ipcListEnabled, ipcList.data, ipcList.status.hasError]);

  useEffect(() => {
    if (!collectionListEnabled || collectionList.data || collectionList.status.hasError) {
      collectionListCurrent?.callback?.((collectionList.data ?? []).map((c) => ({
        collection: c,
        label: c.name,
        value: +c.collectionId
      })));
      if (utils.isDefined(collectionListCurrent)) {
        setCollectionListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [collectionListCurrent, collectionListEnabled, collectionList.data, collectionList.status.hasError]);

  useEffect(() => {
    if (!sourceListEnabled || sourceList.data || sourceList.status.hasError) {
      sourceListCurrent?.callback?.((sourceList.data ?? []).map((s) => ({
        source: s,
        label: s.name,
        value: +s.sourceId
      })));
      if (utils.isDefined(sourceListCurrent)) {
        setSourceListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [sourceListCurrent, sourceListEnabled, sourceList.data, sourceList.status.hasError]);

  useEffect(() => {
    if (!linkSuggestionEnabled || linkSuggestionList.data || linkSuggestionList.status.hasError) {
      const ls = linkSuggestionList.status.hasError ? {} : linkSuggestions;

      linkSuggestionCurrent?.callback?.(ls?.[linkSuggestionCurrent?.link] ?? []);
      if (utils.isDefined(linkSuggestionCurrent)) {
        setLinkSuggestionSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [linkSuggestionCurrent, linkSuggestionEnabled,
    linkSuggestionList.status.hasError, linkSuggestionList.data, linkSuggestions]);

  useEffect(() => {
    if (!cocNumberSuggestionEnabled || cocNumberSuggestionList.data || cocNumberSuggestionList.status.hasError) {
      cocNumberSuggestionCurrent?.callback?.((cocNumberSuggestionList.data?.data ?? [])
        .map((coc) => ({
          label: coc.cocNumber,
          value: coc.cocNumber,
          title: (!coc.consolidated || !coc.consolidatedFor?.name) ? coc.name :
            `${coc.name} (for ${coc.consolidatedFor?.name})`,
          subtitle: utils.address2String(coc.address),
          number: coc.cocNumber,
          chip: coc.consolidated ? 'consolidated' : null,
          consolidated: coc.consolidated,
          consolidatedFor: coc.consolidatedFor
        })));
      if (utils.isDefined(cocNumberSuggestionCurrent)) {
        setCocNumberSuggestionSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [cocNumberSuggestionCurrent, cocNumberSuggestionEnabled, cocNumberSuggestionList.data, cocNumberSuggestionList.status.hasError]);

  useEffect(() => {
    if (!creditSafeIdSuggestionEnabled || creditSafeIdSuggestionList.data || creditSafeIdSuggestionList.status.hasError) {
      creditSafeIdSuggestionCurrent?.callback?.((creditSafeIdSuggestionList.data?.data ?? [])
        .map((creditSafe) => ({
          label: creditSafe.id,
          value: creditSafe.id,
          title: (!creditSafe.consolidated || !creditSafe.consolidatedFor?.name) ? creditSafe.name :
            `${creditSafe.name} (for ${creditSafe.consolidatedFor?.name})`,
          subtitle: utils.address2String(creditSafe.address),
          number: creditSafe.id,
          chip: creditSafe.consolidated ? 'consolidated' : null,
          consolidated: creditSafe.consolidated,
          consolidatedFor: creditSafe.consolidatedFor
        })));
      if (utils.isDefined(creditSafeIdSuggestionCurrent)) {
        setCreditSafeIdSuggestionSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [creditSafeIdSuggestionCurrent, creditSafeIdSuggestionEnabled, creditSafeIdSuggestionList.data, creditSafeIdSuggestionList.status.hasError]);

  return useMemo(() => {
    return {
      ...clientCallbacks,
      entities: ({key, search, ids, filter, callback}) => {
        setEntitiesListSearch((current) => ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}));
      },
      tags: ({key, search, ids, filter, callback}) => {
        setTagListSearch((current) => ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}));
      },
      cpcs: ({key, search, ids, filter, callback}) => {
        setCpcListSearch((current) => ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}));
      },
      ipcs: ({key, search, ids, filter, callback}) => {
        setIpcListSearch((current) => ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}));
      },
      collections: ({key, search, ids, filter, callback}) => {
        setCollectionListSearch((current) => ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}));
      },
      sources: ({key, search, ids, filter, callback}) => {
        setSourceListSearch((current) => ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}));
      },
      linkSuggestions: ({key, search, ids, filter, context, callback}) => {
        setLinkSuggestionSearch((current) => ({
          ...current,
          [key ?? Date.now()]: {
            search: !context?.name ? (search ? search : '') :
              `"${context.name}"${context?.country ? ` ${context.country.toUpperCase()}` : ''}`,
            name: context?.name,
            ids, filter,
            link: context?.link ?? 'link',
            callback
          }
        }));
      },
      cocNumberSuggestions: ({key, search, ids, filter, context, callback}) => {
        setCocNumberSuggestionSearch((current) => ({
          ...current,
          [key ?? Date.now()]: {
            search: search,
            name: context?.legalName || context?.name,
            country: context?.country,
            ids, filter,
            callback
          }
        }));
      },
      creditSafeIdSuggestions: ({key, search, ids, filter, context, callback}) => {
        setCreditSafeIdSuggestionSearch((current) => ({
          ...current,
          [key ?? Date.now()]: {
            search: search,
            name: context?.legalName || context?.name,
            country: context?.country,
            website: context?.website,
            city: context?.city,
            address: context?.address,
            cocNumber: context?.cocNumber,
            ids, filter,
            callback
          }
        }));
      },
    }
  }, [clientCallbacks]);
}

export function getEntityCsi (entity, teamId) {
  if (utils.isDefined(teamId) && entity?.csi) {
    const csiArray = utils.toArray(entity.csi)
      .concat(entity.dsi)
      .map((csi) => utils.camelcaseEx(csi));

    return csiArray.find((csi) => csi.clientId === teamId);
  } else {
    return null;
  }
}

export function useEntityCsi (entity) {
  const teamId = useAuthTeamId();

  return useMemo(() => {
    return getEntityCsi(entity, teamId);
  }, [teamId, entity]);
}

export function useEntityDealLeader (entity) {
  const csi = useEntityCsi(entity);
  return useMemo(() => utils.camelcase(csi?.leader), [csi?.leader]);
}

export function useEntityRelevancy (entity, collection = null) {
  const csi = useEntityCsi(entity);

  return useMemo(() => {
    if (csi) {
      if (utils.isDefined(collection?.collectionId)) {
        return csi.collectionRelevancyClient;
      } else {
        return csi.relevancyClient;
      }
    } else {
      return null;
    }
  }, [csi, collection]);
}

export function useEntityRelevancies (entity, collection = null) {
  const csi = useEntityCsi(entity);
  const collectionId = collection?.collectionId;

  return useMemo(() => {
    if (csi?.kvstorage?.clientRelevancies?.length > 0) {
      let relevancies;
      if (collectionId) {
        relevancies = csi.kvstorage.clientRelevancies.filter((r) => {
          return +r.userId > 0 && +r.collectionId === +collectionId;
        });
      } else {
        relevancies = csi.kvstorage.clientRelevancies.filter((r) => {
          return +r.userId > 0 && !r.collectionId;
        });
      }
      return relevancies || null;
    } else {
      return null;
    }
  }, [collectionId, csi]);
}

export function useEntityPersonalRelevancy (entity, collection = null) {
  const csi = useEntityCsi(entity);
  const userId = useAuthUserId();
  const collectionId = collection?.collectionId;

  return useMemo(() => {
    if (csi?.kvstorage?.clientRelevancies?.length > 0) {
      let relevancy;
      if (collectionId) {
        relevancy = csi.kvstorage.clientRelevancies.find((r) => {
          return +r.userId === +userId && +r.collectionId > 0 && +r.collectionId === +collectionId;
        });
      } else {
        relevancy = csi.kvstorage.clientRelevancies.find((r) => {
          return +r.userId === +userId && !r.collectionId;
        });
      }
      return (relevancy && relevancy.relevancy) || null;
    } else {
      return null;
    }
  }, [collectionId, csi, userId]);
}

export function useEntityExternalRelevancy (entity, collection = null) {
  const csi = useEntityCsi(entity);
  const collectionId = collection?.collectionId;

  return useMemo(() => {
    if (csi) {
      if (collectionId) {
        return csi.collectionRelevancyUs;
      } else {
        return csi.relevancyUs;
      }
    } else {
      return null;
    }
  }, [csi, collectionId]);
}

export function useEntityLinks (entity) {
  return useMemo(() => {
    return entity?.links?.filter((l) => Boolean(l.value)).map((l) => {
      const linkDef = constants.data.lookup('links', l.name.toLowerCase());
      return {
        ...utils.camelcase(l),
        url: l.value,
        ...linkDef
      }
    });
  }, [entity?.links]);
}

export function useLinkSuggestions (data, name) {
  return useMemo(() => {
    const processGoogle = (link, google) => {
      let cleanLink = !link.clean ? google.link : utils.cleanUrl(google.link);
      let cleanValue = !link.clean ? google.link : (
        link.value === 'website' ? utils.cleanWebsite(google.link) : utils.cleanUrl(google.link)
      );

      return {
        label: cleanValue,
        value: cleanValue,
        title: google.title ?? google.displayLink,
        subtitle: google.snippet,
        icon: link.icon,
        button: 'Use URL',
        link: cleanLink
      }
    }

    if (data) {
      const options = {};
      data.forEach((google) => {
        let added = false;
        constants.data.links.forEach((link) => {
          options[link.value] = options[link.value] ?? [];

          let found = false;
          if (link.value !== 'link') {
            if (link.value === 'website') {
              if (utils.nameWebsiteMatch(name, google.link)) {
                found = true;
              }
            } else {
              if (google.labels && google.labels.find((l) => l.name?.toLowerCase() === link.value)) {
                found = true;
              } else if (link.regexp && google.link?.match?.(link.regexp)) {
                found = true;
              } else if (utils.url(google.link)?.hostname?.split('.').includes(link.hostname ?? link.value)) {
                found = true;
              }

              if (found && link.value === 'linkedin') {
                found = Boolean(utils.cleanLinkedInHandle(google.link));
              }
            }
          }

          if (found) {
            added = true;
            options[link.value].push(processGoogle(link, google));
          }
        });

        const other = constants.data.links.find((l) => l.value === 'link')
        if (!added && other) {
          options[other.value].push(processGoogle(other, google));
        }
      });

      Object.keys(options).forEach((k) => {
        options[k] = utils.uniqueArray(options[k], 'value');
      });

      return options;
    }
  }, [data, name]);
}

export function useEntityPatch () {
  const entityUpdate = useEntityUpdate();

  return useCallback((entity, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(entity, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return entityUpdate.mutation.mutateAsync({
        entityId: entity.entityId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [entityUpdate.mutation]);
}

export function useEntitySelected (clientId) {
  const [state, setState] = useState({});

  const selectedEnabled = utils.isDefined(state.listState);
  const selected = useEntityList({
    clientId,
    search: state.ids?.length > 0 ? null : state.listState?.search,
    filter: state.ids?.length > 0 ? null : state.listState?.filter,
    sort: state.listState?.sort,
    entityIds: state.ids,
    page: state.ids?.length > 0 ? 0 : state.page,
    pageSize: state.ids?.length > 0 ? state.ids?.length : state.pageSize,
    minimal: state.minimal
  }, {
    ...constants.queryOptions.runOnceNotStale,
    overrideDataType: constants.dataTypes.other,
    enabled: selectedEnabled && (clientId >= 0)
  });

  useEffect(() => {
    if (!selectedEnabled || selected.data || selected.status.hasError) {
      if (!selected.status.hasError) {
        state.success?.(selected.data.map(processEntity));
      } else {
        state.error?.();
      }
      if (selectedEnabled) {
        setState({});
      }
    }
  }, [state, selectedEnabled, selected.data, selected.status.hasError]);

  return useCallback((listState, page, pageSize, ids, minimal, success, error) => {
    setState({listState, page, pageSize, ids, minimal, success, error});
  }, []);
}

// groups, columns and other definitions
export function useEntityGroups (view, collection, customFields) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    if (customFields && tagGroups) {
      const groups = [];

      Object.keys(constants.entity.groupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.entity.groupDefinition[k])) {
          if (!groups.find((g) => g.name === k)) {
            const groupDef = constants.groupDefinition.lookup('entity', view, k, false, utils.mergeObjects);

            groups.push({
              ...groupDef,
              name: k,
              position: groupDef.position ??
                groups.reduce((p, g) => Math.max(p, g.position), 0),
              fields: utils.object2Array(groupDef.fields).map((f, idx) => ({
                ...f,
                entity: 'entity',
                position: f.position ?? idx,
                auth: {
                  read: utils.createAuth({attribute:`entity.field.${f.name}.read`}),
                  update: utils.createAuth({attribute:`entity.field.${f.name}.update`})
                },
                required: f.required ?? false
              })) ?? []
            });
          }
        }
      });

      let group = groups.find((g) => g.name === 'clientTags');
      const hasPoints = tagGroups?.some((tg) => tg.hasPoints);
      group.fields = group.fields.filter((f) => hasPoints || !f.has?.includes('points'));

      const lastPos = group.fields.reduce((p, f) => Math.max(p, f.position), 0);
      tagGroups?.forEach((tg) => {
        group.fields.push({
          name: `tagGroup${tg.groupId}`,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          variant: 'tagGroups',
          entity: 'entity',
          position: lastPos + +(tg.pos || 1),
          auth: {
            read: utils.createAuth({attribute: 'entity.field.clientTags.read'}),
            update: utils.createAuth({attribute: 'entity.field.clientTags.update'}),
          }
        });
      });

      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);
        return pos ? pos : (
          (+a.clientId - +b.clientId) ? (+a.clientId - +b.clientId) : (
            (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '')?.toLowerCase()) ?
              (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '').toLowerCase()) : (
                a.label?.localeCompare(b.label) ? a.label?.localeCompare(b.label) : +b.fieldId - +a.fieldId
              )
          )
        );
      })
        .forEach((cf) => {
          const groupDef = constants.groupDefinition.lookup('entity', view, cf.name, true, utils.mergeObjects);
          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {}).find((k) => k === utils.camelcase(cf.name))];

          if (utils.isDefined(groupDef?.name ?? cf.groupName)) {
            let grps = groups.filter((g) => g.name === groupDef?.name).concat(
              groups.filter((g) => g.title && cf.groupName && g.title.toLowerCase() === cf.groupName.toLowerCase())
            );
            let locked = Boolean(grps.find((g) => !groupDef && g.locked));
            let group = grps.find((g) => groupDef || !g.locked);

            if (!group) {
              const lastPos = groups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);

              group = {
                ...groupDef,
                locked: false,
                name: `${groupDef?.name ?? utils.camelcase(cf.groupName)}${locked ? 'Locked' : ''}`,
                title: groupDef?.title ?? utils.upperFirst(cf.groupName),
                position: +(cf.groupPosition ?? groupDef?.position ?? (lastPos + 1)),
                fields: []
              };
              groups.push(group);
            } else {
              group.position = +(cf.groupPosition ?? group.position);
            }

            const lastPos = group.fields.reduce((p, f) => Math.max(p, f.position ?? 0), 0);
            const fieldIndex = group.fields.findIndex((f) => f.name === utils.camelcase(cf.name));
            const isLink = Boolean(cf.dataType === constants.fieldDataTypes.url && constants.data.lookup('links', cf.name));
            const field = {
              ...fieldDef,
              customField: cf,
              name: cf.name,
              entity: 'entity',
              link: isLink ? constants.data.lookup('links', cf.name) : null,
              context: isLink ? {
                ...fieldDef?.context,
                link: constants.data.lookup('links', cf.name)?.value
              } : fieldDef?.context,
              variant: fieldDef?.variant ?? (isLink ? 'links' : 'detail'),
              options: fieldDef?.options ?? (isLink ? 'linkSuggestions' : null),
              auth: {
                read: utils.createAuth({attribute:`entity.field.${utils.camelcase(cf.name)}.read`}),
                update: utils.createAuth({attribute:`entity.field.${utils.camelcase(cf.name)}.update`})
              },
              position: +(cf.position ?? fieldDef?.position ?? (lastPos + 1)),
              required: fieldDef?.required ?? false
            };

            if (fieldIndex !== -1) {
              group.fields[fieldIndex] = {
                ...group.fields[fieldIndex],
                ...field
              }
            } else {
              group.fields.push(field);
            }
          }
        });

      return groups;
    } else {
      return null;
    }
  }, [tagGroups, customFields, view]);
}

export function useEntityColumns (view, collection, customFields) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    if (customFields) {
      let columns = [];
      const placeholders = ['clientTags'];

      const presetDefs = constants.presetDefinition.lookup('entity', view);

      Object.keys(constants.entity.columnDefinition).forEach((k) => {
        if (!utils.isFunction(constants.entity.columnDefinition[k]) && !placeholders.includes(k)) {
          const groupDef = constants.groupDefinition.lookup('entity', view, k, true, utils.mergeObjects);
          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {}).find((k1) => k1 === k)];
          const columnDef = constants.columnDefinition.lookup('entity', view, k, utils.mergeObjects);

          const lastGroupPos = Math.floor(columns.reduce((p, c) => Math.max(p, c.position), 0) / constants.numbers.position.groupGap);
          const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
          const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (+p + 1) : p, groupDef?.name ? 0 : null);
          const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
            ((firstPosInGroup ?? 0) === 0 && lastGroupPos > 0) ? (lastGroupPos + 1) :
              ((firstPosInGroup > 0) ? (Math.floor((firstPosInGroup - 1) / constants.numbers.position.groupGap)) :
                (firstPosInGroup < 0 ? (Math.floor((firstPosInGroup + 1) / constants.numbers.position.groupGap)) :
                  (lastGroupPos + 1)))
          )) * constants.numbers.position.groupGap;
          const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (
            groupPos > 0 ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) :
              (groupPos - (fieldDef?.position ?? (lastPosInGroup + 1)))
          ) : (groupPos + 1));

          if (!columns.find((c) => c.name === k)) {
            columns.push({
              ...fieldDef,
              ...columnDef,
              id: columnDef.sortingKey ?? k,
              name: k,
              entity: 'entity',
              hidden: columnDef?.hidden,
              header: columnDef?.header ?? fieldDef?.label,
              group: columnDef.group ?? groupDef,
              presets: columnDef.presets ?? presetDefs,
              position: columnDef.position ?? fieldPos,
              fieldPosition: columnDef.group ? (columnDef.position ?? fieldPos) : (fieldDef?.position ?? lastPosInGroup),
              auth: {
                read: utils.createAuth({attribute:`entity.column.${k}.read`}),
                update: utils.createAuth({attribute:`entity.column.${k}.update`})
              }
            });
          }
        }
      });

      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);
        return pos ? pos : (
          (+a.clientId - +b.clientId) ? (+a.clientId - +b.clientId) : (
            (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '')?.toLowerCase()) ?
              (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '').toLowerCase()) : (
                a.label?.localeCompare(b.label) ? a.label?.localeCompare(b.label) : +b.fieldId - +a.fieldId
              )
          )
        );
      })
        .forEach((cf) => {
          let groupDef = constants.groupDefinition.lookup('entity', view, cf.name, true, utils.mergeObjects) ??
            constants.groupDefinition.lookup('entity', view, cf.groupName, false, utils.mergeObjects);

          // custom fields always have groups
          groupDef = {
            ...groupDef,
            name: groupDef?.name ?? utils.camelcase(cf.groupName),
            title: groupDef?.title ?? utils.upperFirst(cf.groupName)
          };

          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
            .find((k) => k === utils.camelcase(cf.name))];
          const columnDef = constants.columnDefinition.lookup('entity', view, cf.name, utils.mergeObjects);

          const lastGroupPos = Math.floor(columns.reduce((p, c) => Math.max(p, c.position), 0) / constants.numbers.position.groupGap);
          const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
          const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (+p + 1) : p, groupDef?.name ? 0 : null);
          const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
            ((firstPosInGroup ?? 0) === 0 && lastGroupPos > 0) ? (lastGroupPos + 1) :
              ((firstPosInGroup > 0) ? (Math.floor((firstPosInGroup - 1) / constants.numbers.position.groupGap)) :
                (firstPosInGroup < 0 ? (Math.floor((firstPosInGroup + 1) / constants.numbers.position.groupGap)) :
                  (lastGroupPos + 1)))
          )) * constants.numbers.position.groupGap;

          let columnIndex = columns.findIndex((c) => c.name === utils.camelcase(cf.name));
          if (columnIndex === -1) {
            columns.push({
              ...fieldDef,
              ...columnDef,
              entity: 'entity'
            });
            columnIndex = columns.length - 1;
          }
          const columnPos = (
            utils.isDefined(cf.position ?? fieldDef?.position ?? lastPosInGroup) ?
              (groupPos > 0 ? (groupPos + (cf.position ?? fieldDef?.position ?? (lastPosInGroup + 1))) :
                (groupPos - (cf.position ?? fieldDef?.position ?? (lastPosInGroup + 1)))) :
              (columns[columnIndex].position ?? (lastGroupPos + 1))
          )

          const isBoolean = Boolean(cf.renderer === constants.fieldRenderers.boolean);
          const isLink = Boolean(cf.dataType === constants.fieldDataTypes.url && constants.data.lookup('links', cf.name));
          columns[columnIndex] = {
            ...columns[columnIndex],
            id: columns[columnIndex].sortingKey ?? cf.name,
            name: cf.name,
            group: columns[columnIndex].group ?? groupDef,
            presets: columns[columnIndex].presets ?? presetDefs,
            header: columns[columnIndex].header ?? cf.label,
            customField: cf,
            link: isLink ? constants.data.lookup('links', cf.name) : null,
            type: columns[columnIndex].type ?? (isBoolean ? constants.formFieldTypes.list : null),
            conversion: columns[columnIndex].conversion ?? (isBoolean ? constants.formFieldConversionTypes.value : null),
            context: isLink ? {
              ...fieldDef?.context,
              link: constants.data.lookup('links', cf.name)?.value
            } : fieldDef?.context,
            options: columns[columnIndex].options ?? (
              isLink ? 'linkSuggestions' : (isBoolean ? 'toggleYesNoInverted' : null)
            ),
            auth: {
              read: utils.createAuth({attribute: `entity.column.${utils.camelcase(cf.name)}.read`}),
              update: utils.createAuth({attribute: `entity.column.${utils.camelcase(cf.name)}.update`})
            },
            position: columnDef?.position ? columnDef.position : columnPos,
            fieldPosition: columns[columnIndex].fieldPosition ?? fieldDef?.position ?? lastPosInGroup,
            visible: columns[columnIndex].visible ?? false,
            sortable: columnDef?.sortable ?? true,
            required: columnDef?.required ?? false,
            FormFieldProps: {
              ...columns[columnIndex]?.FormFieldProps,
              showTooltip: ['markdown', 'textarea'].includes(cf.renderer)
            }
          };

          if (isBoolean) {

          }
        });

      const hasPoints = tagGroups?.some((tg) => tg.hasPoints);
      columns = columns.filter((c) => hasPoints || !c.has?.includes('points'));

      tagGroups?.forEach((tg) => {
        const columnDef = constants.columnDefinition.lookup('entity', view, 'clientTags', utils.mergeObjects);
        const groupDef = constants.groupDefinition.lookup('entity', view, 'clientTags', false, utils.mergeObjects);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? constants.numbers.position.maxPosition) * constants.numbers.position.groupGap;
        const lastPos = Object.keys(groupDef.fields || {}).reduce((p, k) => Math.max(p, groupDef.fields[k].tablePosition ?? groupDef.fields[k].position), 0);
        columns.push({
          ...columnDef,
          id: `dropdown_${tg.groupId}`, // sortingKey
          name: `tagGroup${tg.groupId}`,
          entity: 'entity',
          group: groupDef ? {
            name: 'clientTags',
            ...groupDef
          } : null,
          presets: presetDefs,
          sortingKey: `dropdown_${tg.groupId}`,
          header: tg.name,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          position: lastPos + groupPos + (tg.pos || 1),
          fieldPosition: tg.pos,
          required: false,
          visible: columnDef?.visible ?? false,
          auth: {
            read: utils.createAuth({attribute: 'entity.column.clientTags.read'}),
            update: utils.createAuth({attribute: 'entity.column.clientTags.update'}),
          },
          FormFieldProps: {
            ChipListProps: {
              variant: 'compact'
            }
          }
        });
      });

      return columns;
    } else {
      return null;
    }
  }, [customFields, tagGroups, view]);
}

export function useEntityFilterGroups (view, collection, customFields, clientId) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    const filterGroups = [];
    if (customFields && tagGroups) {
      Object.keys(constants.entity.filterGroupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.entity.filterGroupDefinition[k])) {
          const groupDef = constants.filterGroupDefinition.lookup('entity', view, k, false, utils.mergeObjects);
          const filters = utils.isFunction(groupDef.filters) ? groupDef.filters(view) : groupDef.filters;
          const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
          filterGroups.push({
            ...groupDef,
            name: k,
            position: (groupDef.position ?? (lastPos + 1)),
            filters: Object.keys(filters ?? {}).map((k, idx) => {
              const f = filters[k];
              return {
                ...f,
                id: f.filterKey ?? k,
                position: f.position ?? (idx + 1),
                name: k,
                entity: 'entity',
                auth: {
                  read: utils.createAuth({attribute: `entity.filter.${k}.read`}),
                  update: utils.createAuth({attribute: `entity.filter.${k}.update`}),
                }
              }
            })
          });
        }
      });

      let groupDef = constants.filterGroupDefinition.lookup('entity', view, 'customFields', true, utils.mergeObjects);
      let group = filterGroups.find((g) => g.name === groupDef.name);
      let filter = group.filters.find((f) => f.name === 'customFields');
      group.filters = group.filters.filter((f) => f.name !== 'customFields');

      let filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);
        return pos ? pos : (
          (+a.clientId - +b.clientId) ? (+a.clientId - +b.clientId) : (
            (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '')?.toLowerCase()) ?
              (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '').toLowerCase()) : (
                a.label?.localeCompare(b.label) ? a.label?.localeCompare(b.label) : +b.fieldId - +a.fieldId
              )
          )
        );
      })?.forEach((cf) => {
        const typeToFilterProps = () => {
          if (cf.renderer === constants.fieldRenderers.monetary) {
            return {
              monetary: true,
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'moneyRange',
              FormFieldProps: {
                multiple: true
              }
            }
          } else if (cf.renderer === constants.fieldRenderers.numeric) {
            return {
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'mediumRange',
              FormFieldProps: {
                multiple: true
              }
            }
          } else if (cf.renderer === constants.fieldRenderers.date) {
            return {
              placeholder: 'Search month',
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'monthsFrom',
              FormFieldProps: {
                showSearch: true,
                multiple: true
              }
            }
          } else if (cf.renderer === constants.fieldRenderers.boolean) {
            return {
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'toggleYesNo',
              FormFieldProps: {
                multiple: true
              }
            }
          } else {
            return {
              type: constants.formFieldTypes.text
            }
          }
        }

        // default custom fields are handled already
        if (+cf.clientId > 0 && +cf.clientId === +clientId) {
          if (constants.data.lookup('customFieldRendererTypes', cf.renderer)) {
            filterPos += 1;
            group.filters.push({
              ...filter,
              id: cf.name, // filterKey
              name: cf.name,
              label: cf.label,
              filterKey: cf.name,
              customField: cf,
              description: '',
              position: +(cf.position ?? filterPos),
              auth: {
                read: utils.createAuth({attribute: 'entity.filter.customFields.read'}),
                update: utils.createAuth({attribute: 'entity.filter.customFields.update'}),
              },
              ...(typeToFilterProps())
            });
          }
        }
      });

      // lookup group for name
      groupDef = constants.filterGroupDefinition.lookup('entity', view, 'clientTags', true, utils.mergeObjects);
      group = filterGroups.find((g) => g.name === groupDef.name);
      filter = group.filters.find((f) => f.name === 'clientTags');
      group.filters = group.filters.filter((f) => f.name !== 'clientTags');

      filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      tagGroups?.forEach((tg) => {
        group.filters.push({
          ...filter,
          id: `group_${tg.groupId}`, // filterKey
          name: `tagGroup${tg.groupId}`,
          label: tg.name,
          validation: `${constants.formFieldValidationTypes.list}(${constants.formFieldValidationTypes.int})`,
          filterKey: `group_${tg.groupId}`,
          type: constants.formFieldTypes.list,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          position: (filterPos + tg.pos),
          auth: {
            read: utils.createAuth({attribute: 'entity.filter.clientTags.read'}),
            update: utils.createAuth({attribute: 'entity.filter.clientTags.update'}),
          },
          FormFieldProps: {
            multiple: true
          }
        });
      });

      return filterGroups;
    } else {
      return null;
    }
  }, [customFields, tagGroups, view, clientId]);
}

export function useEntityPanels (view, client) {
  const groups = client?.dealflowGroups;

  return useMemo(() => {
    if (groups) { // any view
      return groups.map((group) => ({
        name: group.groupId.toString(),
        id: +group.groupId,
        title: group.label,
        color: utils.deprecatedColor(group.color),
        position: group.position,
        subPanels: group.statuses.reduce((a, status) => {
          if (+status.statusId !== 0) {
            a.push({
              name: status.statusId.toString(),
              id: +status.statusId,
              title: status.name,
              color: utils.deprecatedColor(group?.color ? group.color : status?.color),
              position: status.position,
            });
          }
          return a;
        }, [])
      })).filter((p) => p.subPanels.length > 0);
    }
  }, [groups]);
}

export function useEntitySections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.entity.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.entity.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('entity', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `entity.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `entity.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            ...cardDef,
            auth: {
              read: utils.createAuth({attribute: `entity.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `entity.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}


export function useEntityGraphs (view) {
  return useMemo(() => {
    const graphs = [];
    Object.keys(constants.entity.graphDefinition).forEach((k) => {
      if (!utils.isFunction(constants.entity.graphDefinition[k])) {
        const graphDef = constants.graphDefinition.lookup('entity', view, k, utils.mergeObjects);
        const lastPos = graphs.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        graphs.push({
          ...graphDef,
          name: k,
          position: (graphDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `entity.graph.${graphDef.name}.read`}),
            update: utils.createAuth({attribute: `entity.graph.${graphDef.name}.update`})
          }
        });
      }
    });

    return graphs;
  }, [view]);
}

export function useEntitySettings () {
  const authorize = useAuthorize();
  const entityProfileCallbacks = useEntityCallbacks();

  const client = useAuthClient();
  const clientCustomFields = useClientCustomFieldCache({clientId: client?.clientId},
    {enabled: client?.clientId >= 0});
  const clientCustomFieldsCache = (clientCustomFields.data?.data ?? clientCustomFields.data);

  const universeCollection = useCollectionGet({collectionId: client?.universeCollectionId},
    {enabled: Boolean(client?.universeCollectionId)});

  const fieldData = useMemo(() => ({
    currency: constants.data.lookup('currencies', client?.props?.currency),
    customFields: clientCustomFieldsCache ?? [],
    tagGroups: universeCollection?.data?.tagGroups,
    callbacks: entityProfileCallbacks
  }), [entityProfileCallbacks, clientCustomFieldsCache, universeCollection?.data?.tagGroups, client?.props?.currency]);

  const entityFilterGroups = useEntityFilterGroups(null, universeCollection?.data, fieldData.customFields, client?.clientId);

  const filterGroups = useMemo(() => {
    if (entityFilterGroups?.length > 0) {
      return entityFilterGroups
        .filter((filterGroupDef) => !filterGroupDef.hidden && authorize(filterGroupDef.auth?.read ?? {}))
        .map((filterGroupDef) => {
          filterGroupDef.filters = (filterGroupDef.filters ?? [])
            .filter((f) => !f.hidden && authorize(f.auth?.read ?? {}));
          return filterGroupDef;
        });
    } else {
      return null;
    }
  }, [authorize, entityFilterGroups]);

  return [fieldData, filterGroups, universeCollection?.data, fieldData.customFields, client];
}

// dealflow
export function useDealflowEntityGroups (view) {
  return useMemo(() => {
    const groups = [];

    Object.keys(constants.dealflow.entity.groupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.groupDefinition[k])) {
        if (!groups.find((g) => g.name === k)) {
          const groupDef = constants.groupDefinition.lookup('dealflow.entity', view, k, false, utils.mergeObjects);

          groups.push({
            ...groupDef,
            name: k,
            position: groupDef.position ??
              groups.reduce((p, g) => Math.max(p, g.position), 0),
            fields: utils.object2Array(groupDef.fields).map((f, idx) => ({
              ...f,
              entity: 'entity',
              position: f.position ?? idx,
              auth: {
                read: utils.createAuth({attribute:`dealflow.entity.field.${f.name}.read`}),
                update: utils.createAuth({attribute:`dealflow.entity.field.${f.name}.update`})
              },
              required: f.required ?? false
            })) ?? []
          });
        }
      }
    });

    return groups;
  }, [view]);
}

export function useDealflowEntityColumns (view) {
  return useMemo(() => {
    const columns = [];
    Object.keys(constants.dealflow.entity.columnDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.columnDefinition[k])) {
        const groupDef = constants.groupDefinition.lookup('dealflow.entity', view, k, true, utils.mergeObjects);
        const presetDefs = constants.presetDefinition.lookup('dealflow.entity', view);
        const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
          .find((k1) => k1 === k)];
        const columnDef = constants.columnDefinition.lookup('dealflow.entity', view, k, utils.mergeObjects);

        const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
        const lastGroupPos = Math.floor(lastPos / constants.numbers.position.groupGap);
        const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
        const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (+p + 1) : p, groupDef?.name ? 0 : null);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
          ((firstPosInGroup ?? 0) === 0 && lastGroupPos > 0) ? (lastGroupPos + 1) :
            ((firstPosInGroup > 0) ? (Math.floor((firstPosInGroup - 1) / constants.numbers.position.groupGap)) :
              (firstPosInGroup < 0 ? (Math.floor((firstPosInGroup + 1) / constants.numbers.position.groupGap)) :
                (lastGroupPos + 1)))
        )) * constants.numbers.position.groupGap;
        const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (
          groupPos > 0 ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) :
            (groupPos - (fieldDef?.position ?? (lastPosInGroup + 1)))
        ) : (lastPos + 1));

        if (!columns.find((c) => c.name === k)) {
          columns.push({
            ...fieldDef,
            ...columnDef,
            id: columnDef.sortingKey ?? k,
            name: k,
            entity: 'entity',
            hidden: columnDef?.hidden,
            header: columnDef?.header ?? fieldDef?.label,
            group: columnDef.group ?? groupDef,
            presets: columnDef.presets ?? presetDefs,
            position: columnDef.position ?? fieldPos,
            fieldPosition: columnDef.group ? (columnDef.position ?? fieldPos) : (fieldDef?.position ?? lastPosInGroup),
            auth: {
              read: utils.createAuth({attribute:`dealflow.entity.column.${k}.read`}),
              update: utils.createAuth({attribute:`dealflow.entity.column.${k}.update`})
            }
          });
        }
      }
    });

    return columns;
  }, [view]);
}

export function useDealflowEntityFilterGroups (view) {
  return useMemo(() => {
    const filterGroups = [];
    Object.keys(constants.dealflow.entity.filterGroupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.filterGroupDefinition[k])) {
        const groupDef = constants.filterGroupDefinition.lookup('dealflow.entity', view, k, false, utils.mergeObjects);
        const filters = utils.isFunction(groupDef.filters) ? groupDef.filters(view) : groupDef.filters;
        const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
        filterGroups.push({
          ...groupDef,
          name: k,
          position: (groupDef.position ?? (lastPos + 1)),
          filters: Object.keys(filters ?? {}).map((k, idx) => {
            const f = filters[k];
            return {
              ...f,
              id: f.filterKey ?? k,
              name: k,
              position: f.position ?? (idx + 1),
              entity: 'entity',
              auth: {
                read: utils.createAuth({attribute: `dealflow.entity.filter.${k}.read`}),
                update: utils.createAuth({attribute: `dealflow.entity.filter.${k}.update`}),
              }
            }
          })
        });
      }
    });

    return filterGroups;
  }, [view]);
}

export function useDealflowEntitySections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.dealflow.entity.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('dealflow.entity', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `dealflow.entity.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `dealflow.entity.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            ...cardDef,
            auth: {
              read: utils.createAuth({attribute: `dealflow.entity.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `dealflow.entity.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}

export function useDealflowEntityGraphs (view) {
  return useMemo(() => {
    const graphs = [];
    Object.keys(constants.dealflow.entity.graphDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.graphDefinition[k])) {
        const graphDef = constants.graphDefinition.lookup('dealflow.entity', view, k, utils.mergeObjects);
        const lastPos = graphs.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        graphs.push({
          ...graphDef,
          name: k,
          position: (graphDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `dealflow.entity.graph.${graphDef.name}.read`}),
            update: utils.createAuth({attribute: `dealflow.entity.graph.${graphDef.name}.update`})
          }
        });
      }
    });

    return graphs;
  }, [view]);
}

// database
export function useDatabaseEntitySelected (clientId) {
  const [state, setState] = useState({});

  const selectedEnabled = utils.isDefined(state.listState);
  const selectedSearch = useEntitySearch({
    clientId: clientId,
    query: state.listState?.query,
    entityIds: state.ids,
    page: state.page,
    pageSize: state.pageSize,
    minimal: state.minimal
  }, {
    ...constants.queryOptions.runOnceNotStale,
    overrideDataType: constants.dataTypes.other,
    enabled: selectedEnabled && (clientId >= 0) && !(state.ids?.length > 0)
  });

  const selectedIds = useEntityBaseList({
    clientId,
    entityIds: state.ids,
    page: 0,
    pageSize: state.ids?.length,
    minimal: state.minimal
  }, {
    ...constants.queryOptions.runOnceNotStale,
    overrideDataType: constants.dataTypes.other,
    enabled: selectedEnabled && (clientId >= 0) && (state.ids?.length > 0)
  });

  useEffect(() => {
    if (!selectedEnabled || (selectedSearch.data ?? selectedIds.data) || (selectedSearch.status.hasError ?? selectedIds.status.hasError)) {
      if (!(selectedSearch.status.hasError ?? selectedIds.status.hasError)) {
        state.success?.((selectedSearch.data ?? selectedIds.data).map(processEntity));
      } else {
        state.error?.();
      }
      if (selectedEnabled) {
        setState({});
      }
    }
  }, [state, selectedEnabled, selectedSearch.data, selectedSearch.status.hasError, selectedIds.data, selectedIds.status.hasError]);

  return useCallback((listState, page, pageSize, ids, minimal, success, error) => {
    setState({listState, page, pageSize, ids, minimal, success, error});
  }, []);
}

export function useDatabaseEntityGroups (view) {
  return useMemo(() => {
    const groups = [];

    Object.keys(constants.database.entity.groupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.database.entity.groupDefinition[k])) {
        if (!groups.find((g) => g.name === k)) {
          const groupDef = constants.groupDefinition.lookup('database.entity', view, k, false, utils.mergeObjects);

          groups.push({
            ...groupDef,
            name: k,
            position: groupDef.position ??
              groups.reduce((p, g) => Math.max(p, g.position), 0),
            fields: utils.object2Array(groupDef.fields).map((f, idx) => ({
              ...f,
              entity: 'entity',
              position: f.position ?? idx,
              auth: {
                read: utils.createAuth({attribute:`database.entity.field.${f.name}.read`}),
                update: utils.createAuth({attribute:`database.entity.field.${f.name}.update`})
              },
              required: f.required ?? false
            })) ?? []
          });
        }
      }
    });

    return groups;
  }, [view]);
}

export function useDatabaseEntityColumns (view) {
  return useMemo(() => {
    const columns = [];
    Object.keys(constants.database.entity.columnDefinition).forEach((k) => {
      if (!utils.isFunction(constants.database.entity.columnDefinition[k])) {
        const groupDef = constants.groupDefinition.lookup('database.entity', view, k, true, utils.mergeObjects);
        const presetDefs = constants.presetDefinition.lookup('database.entity', view);
        const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
          .find((k1) => k1 === k)];
        const columnDef = constants.columnDefinition.lookup('database.entity', view, k, utils.mergeObjects);

        const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
        const lastGroupPos = Math.floor(lastPos / constants.numbers.position.groupGap);
        const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
        const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (+p + 1) : p, groupDef?.name ? 0 : null);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
          ((firstPosInGroup ?? 0) === 0 && lastGroupPos > 0) ? (lastGroupPos + 1) :
            ((firstPosInGroup > 0) ? (Math.floor((firstPosInGroup - 1) / constants.numbers.position.groupGap)) :
              (firstPosInGroup < 0 ? (Math.floor((firstPosInGroup + 1) / constants.numbers.position.groupGap)) :
                (lastGroupPos + 1)))
        )) * constants.numbers.position.groupGap;
        const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (
          groupPos > 0 ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) :
            (groupPos - (fieldDef?.position ?? (lastPosInGroup + 1)))
        ) : (lastPos + 1));

        if (!columns.find((c) => c.name === k)) {
          columns.push({
            ...fieldDef,
            ...columnDef,
            id: columnDef.sortingKey ?? k,
            name: k,
            entity: 'entity',
            hidden: columnDef?.hidden,
            header: columnDef?.header ?? fieldDef?.label,
            group: columnDef.group ?? groupDef,
            presets: columnDef.presets ?? presetDefs,
            position: columnDef.position ?? fieldPos,
            fieldPosition: columnDef.group ? (columnDef.position ?? fieldPos) : (fieldDef?.position ?? lastPosInGroup),
            auth: {
              read: utils.createAuth({attribute:`database.entity.column.${k}.read`}),
              update: utils.createAuth({attribute:`database.entity.column.${k}.update`})
            }
          });
        }
      }
    });

    return columns;
  }, [view]);
}

export function useDealflowEntityPanels (view) {
  return useMemo(() => {
    if (!view || view) { // any view
      return [];
    }
  }, [view]);
}

export function useDatabaseEntityFilterGroups (view, collection, customFields, clientId) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    const filterGroups = [];
    if (customFields && tagGroups) {
      Object.keys(constants.database.entity.filterGroupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.database.entity.filterGroupDefinition[k])) {
          const groupDef = constants.filterGroupDefinition.lookup('database.entity', view, k, false, utils.mergeObjects);
          const filters = utils.isFunction(groupDef.filters) ? groupDef.filters(view) : groupDef.filters;
          const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
          filterGroups.push({
            ...groupDef,
            name: k,
            position: (groupDef.position ?? (lastPos + 1)),
            filters: Object.keys(filters ?? {}).map((k, idx) => {
              const f = filters[k];
              return {
                ...f,
                id: f.filterKey ?? k,
                name: k,
                position: f.position ?? (idx + 1),
                entity: 'entity',
                auth: {
                  read: utils.createAuth({attribute: `database.entity.filter.${k}.read`}),
                  update: utils.createAuth({attribute: `database.entity.filter.${k}.update`}),
                }
              }
            })
          });
        }
      });

      let groupDef = constants.filterGroupDefinition.lookup('database.entity', view, 'customFields', true, utils.mergeObjects);
      let group = filterGroups.find((g) => g.name === groupDef.name);
      let filter = group.filters.find((f) => f.name === 'customFields');
      group.filters = group.filters.filter((f) => f.name !== 'customFields');


      let filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);
        return pos ? pos : (
          (+a.clientId - +b.clientId) ? (+a.clientId - +b.clientId) : (
            (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '')?.toLowerCase()) ?
              (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '').toLowerCase()) : (
                a.label?.localeCompare(b.label) ? a.label?.localeCompare(b.label) : +b.fieldId - +a.fieldId
              )
          )
        );
      })?.forEach((cf) => {
        const typeToFilterProps = () => {
          if (cf.renderer === constants.fieldRenderers.monetary) {
            return {
              defaultOptions: '',
              options: 'currencyConversions',
              renderer: constants.fieldRenderers.monetary,
              type: constants.formFieldTypes.autocomplete,
              format: constants.formFieldFormatTypes.int,
              validation: constants.formFieldValidationTypes.int,
              conversion: constants.formFieldConversionTypes.value,
              operators: constants.query.filterOperatorGroups.range
            }
          } else if (cf.renderer === constants.fieldRenderers.numeric) {
            return {
              defaultOptions: '',
              options: '',
              type: constants.formFieldTypes.text,
              format: constants.formFieldFormatTypes.int,
              validation: constants.formFieldValidationTypes.int,
              conversion: constants.formFieldConversionTypes.int,
              operators: constants.query.filterOperatorGroups.range
            }
          } else if (cf.renderer === constants.fieldRenderers.date) {
            return {
              defaultOptions: '',
              options: '',
              type: constants.formFieldTypes.date,
              conversion: constants.formFieldConversionTypes.none,
              validation: constants.formFieldValidationTypes.date,
              operators: constants.query.filterOperatorGroups.range
            }
          } else if (cf.renderer === constants.fieldRenderers.boolean) {
            return {
              defaultOptions: '',
              validation: constants.formFieldValidationTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              operators: constants.query.filterOperatorGroups.static,
              options: 'toggleYesNo'
            }
          } else {
            return {
              type: constants.formFieldTypes.text
            }
          }
        }

        if (+cf.clientId > 0 && +cf.clientId === +clientId) {
          if (constants.data.lookup('customFieldRendererTypes', cf.renderer)) {
            filterPos += 1;
            group.filters.push({
              ...filter,
              id: cf.name, // filterKey
              name: cf.name,
              label: cf.label,
              filterKey: cf.name,
              customField: cf,
              description: '',
              position: +(cf.position ?? filterPos),
              auth: {
                read: utils.createAuth({attribute: 'database.entity.filter.customFields.read'}),
                update: utils.createAuth({attribute: 'database.entity.filter.customFields.update'}),
              },
              ...(typeToFilterProps())
            });
          }
        }
      });

      // lookup group for name
      groupDef = constants.filterGroupDefinition.lookup('database.entity', view, 'clientTags', true, utils.mergeObjects);
      group = filterGroups.find((g) => g.name === groupDef.name);
      filter = group.filters.find((f) => f.name === 'clientTags');
      group.filters = group.filters.filter((f) => f.name !== 'clientTags');

      filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      tagGroups?.forEach((tg) => {
        group.filters.push({
          ...filter,
          id: `group_${tg.groupId}`, // filterKey
          name: `tagGroup${tg.groupId}`,
          position: (filterPos + tg.pos),
          auth: {
            read: utils.createAuth({attribute: 'database.entity.filter.clientTags.read'}),
            update: utils.createAuth({attribute: 'database.entity.filter.clientTags.update'}),
          }
        });
      });

      return filterGroups;
    } else {
      return null;
    }
  }, [tagGroups, customFields, view, clientId]);
}

export function useDatabaseEntitySections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.database.entity.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.database.entity.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('database.entity', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `database.entity.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `database.entity.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            ...cardDef,
            auth: {
              read: utils.createAuth({attribute: `database.entity.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `database.entity.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}

export function useDatabaseEntitySettings () {
  const authorize = useAuthorize();

  const [defaultFieldData, defaultFilterGroups, collection, customFields, client] = useEntitySettings();

  const databaseFieldData = useMemo(() => ({}), []);
  const fieldData = useMergeFieldData(defaultFieldData, databaseFieldData);

  const databaseFilterGroups = useDatabaseEntityFilterGroups(null, collection, customFields, client?.clientId);
  const filterGroupsMerged = useMergeFilterGroupDefinitions(defaultFilterGroups, databaseFilterGroups);

  const filterGroups = useMemo(() => {
    if (filterGroupsMerged?.length > 0) {
      return filterGroupsMerged
        .filter((filterGroupDef) => !filterGroupDef.hidden && authorize(filterGroupDef.auth?.read ?? {}))
        .map((filterGroupDef) => {
          filterGroupDef.filters = (filterGroupDef.filters ?? [])
            .filter((f) => !f.hidden && authorize(f.auth?.read ?? {}));
          return filterGroupDef;
        });
    } else {
      return null;
    }
  }, [authorize, filterGroupsMerged]);

  return [fieldData, filterGroups];
}


