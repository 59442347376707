import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useCollectionEntityList,
  useCollectionEntityUpdate
} from 'services/collection/entity/entity.hooks';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {
  processEntity,
  useEntitySettings
} from 'services/entity/entity.utils';
import {useSourceList} from 'services/source/source.hooks';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useCollectionCustomFieldCache} from 'services/collection/customField/customField.hooks';
import {useCollectionGet} from 'services/collection/collection.hooks';
import {useMergeFieldData, useMergeFilterGroupDefinitions} from 'helpers/hooks/utils';

export function processCollectionEntity (entity) {
  return processEntity(entity);
}

export function useCollectionEntityCallbacks (collection) {
  const [collectionSourcesListSearch, setCollectionSourcesListSearch] = useState({});
  const collectionSourcesListCurrent = collectionSourcesListSearch[Object.keys(collectionSourcesListSearch)[0]];
  const collectionSourcesListEnabled = Boolean(collectionSourcesListCurrent?.callback);
  const collectionSourcesList = useSourceList({
    search: collectionSourcesListCurrent?.search,
    filter: utils.addFilter(((utils.toArray(collectionSourcesListCurrent?.ids, true).length > 0) ? [{
      id: 'sourceIds',
      value: utils.toArray(collectionSourcesListCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : [])
      .concat([{id: 'usedIn', value: collection?.collectionId}]),
      collectionSourcesListCurrent?.filter),
    pageSize: Math.max(100, utils.toArray(collectionSourcesListCurrent?.ids, true)?.length)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: collectionSourcesListEnabled && collection?.collectionId > 0
  });

  useEffect(() => {
    if (!collectionSourcesListEnabled || collectionSourcesList.data || collectionSourcesList.status.hasError) {
      const collectionSources = (collection?.projectSources || [])
        .map((ps) => {
          const source = +ps.sourceId <= 0 ? ps : (collectionSourcesList.data ?? []).find((s) => +s.sourceId === +ps.sourceId);

          if (source) {
            return {
              source: source,
              label: +source.sourceId === 0 ? source.type : source.name,
              value: +source.sourceId <= 0 ? (+source.sourceId === 0 ? source.type : `${source.type}${source.sourceId}`) : +source.sourceId
            }
          } else {
            return null;
          }
        })
        .filter((ps) => {
          const must = utils.toArray(collectionSourcesListCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(collectionSourcesListCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return utils.isDefined(ps) && (+ps.value > 0 || ((!collectionSourcesListCurrent?.search ||
            ps.label.toString() === collectionSourcesListCurrent?.search.toString() ||
            ps.label.toLowerCase().includes(collectionSourcesListCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => ps.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => ps.value.toString() === v))
            )
          ));
        });

      collectionSourcesListCurrent?.callback?.(collectionSources.slice(0, 100));
      if (utils.isDefined(collectionSourcesListCurrent)) {
        setCollectionSourcesListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [collectionSourcesListCurrent, collectionSourcesListEnabled, collectionSourcesList.data, collectionSourcesList.status.hasError, collection?.projectSources]);

  return useMemo(() => {
    return {
      collectionSources: ({key, search, ids, filter, callback}) => {
        setCollectionSourcesListSearch((current) => ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}));
      }
    }
  }, []);
}

export function useCollectionEntityPatch () {
  const collectionEntityUpdate = useCollectionEntityUpdate();

  return useCallback((collection, entity, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(entity, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return collectionEntityUpdate.mutation.mutateAsync({
        collectionId: collection.collectionId,
        entityId: entity.entityId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [collectionEntityUpdate.mutation]);
}

export function useCollectionEntitySelected (collectionId) {
  const [state, setState] = useState({});

  const selectedEnabled = utils.isDefined(state.listState);
  const selected = useCollectionEntityList({
    collectionId,
    search: state.ids?.length > 0 ? null : state.listState?.search,
    filter: state.ids?.length > 0 ? state.listState?.filter.filter((f) => f.id === 'scope') : state.listState?.filter,
    sort: state.listState?.sort,
    entityIds: state.ids,
    page: state.ids?.length > 0 ? 0 : state.page,
    pageSize: state.ids?.length > 0 ? state.ids?.length : state.pageSize,
    minimal: state.minimal
  }, {
    ...constants.queryOptions.runOnceNotStale,
    overrideDataType: constants.dataTypes.other,
    enabled: selectedEnabled && collectionId > 0
  });

  useEffect(() => {
    if (!selectedEnabled || selected.data || selected.status.hasError) {
      if (!selected.status.hasError) {
        state.success?.(selected.data.map(processEntity));
      } else {
        state.error?.();
      }
      if (selectedEnabled) {
        setState({});
      }
    }
  }, [state, selectedEnabled, selected.data, selected.status.hasError]);

  return useCallback((listState, page, pageSize, ids, minimal, success, error) => {
    setState({listState, page, pageSize, ids, minimal, success, error});
  }, []);
}

export function useCollectionEntityGroups (view, collection, customFields) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    if (tagGroups && customFields) {
      const groups = [];

      Object.keys(constants.collection.entity.groupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.collection.entity.groupDefinition[k])) {
          if (!groups.find((g) => g.name === k)) {
            const groupDef = constants.groupDefinition.lookup('collection.entity', view, k, false, utils.mergeObjects);

            groups.push({
              ...groupDef,
              name: k,
              position: groupDef.position ??
                groups.reduce((p, g) => Math.max(p, g.position), 0),
              fields: utils.object2Array(groupDef.fields).map((f, idx) => ({
                ...f,
                entity: 'entity',
                position: f.position ?? idx,
                auth: {
                  read: utils.createAuth({attribute:`collection.entity.field.${f.name}.read`}),
                  update: utils.createAuth({attribute:`collection.entity.field.${f.name}.update`})
                },
                required: f.required ?? false
              })) ?? []
            });
          }
        }
      });

      let group = groups.find((g) => g.name === 'collectionTags');
      const hasPoints = tagGroups?.some((tg) => tg.hasPoints);
      group.fields = group.fields.filter((f) => hasPoints || !f.has?.includes('points'));

      const lastPos = group.fields.reduce((p, f) => Math.max(p, f.position), 0);
      tagGroups?.forEach((tg) => {
        group.fields.push({
          name: `tagGroup${tg.groupId}`,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          variant: 'tagGroups',
          entity: 'entity',
          position: lastPos + +(tg.pos || 1),
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.field.collectionTags.read', meta: {tagGroup: tg}}),
            update: utils.createAuth({attribute: 'collection.entity.field.collectionTags.update', meta: {tagGroup: tg}}),
          }
        });
      });

      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);
        return pos ? pos : (
          (+a.clientId - +b.clientId) ? (+a.clientId - +b.clientId) : (
            (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '')?.toLowerCase()) ?
              (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '').toLowerCase()) : (
                a.label?.localeCompare(b.label) ? a.label?.localeCompare(b.label) : +b.fieldId - +a.fieldId
              )
          )
        );
      })
        .forEach((cf) => {
          const groupDef = constants.groupDefinition.lookup('collection.entity', view, cf.name, true, utils.mergeObjects);
          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {}).find((k) => k === utils.camelcase(cf.name))];

          if (utils.isDefined(groupDef?.name ?? cf.groupName)) {
            let grps = groups.filter((g) => g.name === groupDef?.name).concat(
              groups.filter((g) => g.title && cf.groupName && g.title.toLowerCase() === cf.groupName.toLowerCase())
            );
            let locked = Boolean(grps.find((g) => !groupDef && g.locked));
            let group = grps.find((g) => groupDef || !g.locked);

            if (!group) {
              const lastPos = groups.reduce((p, g) => Math.max(p, g.position ?? 0), constants.numbers.position.maxPosition);

              group = {
                ...groupDef,
                locked: false,
                name: `${groupDef?.name ?? utils.camelcase(cf.groupName)}${locked ? 'Locked' : ''}`,
                title: groupDef?.title ?? utils.upperFirst(cf.groupName),
                position: +(cf.groupPosition ?? groupDef?.position ?? (lastPos + 1)),
                fields: []
              };
              groups.push(group);
            } else {
              group.position = +(cf.groupPosition ?? group.position);
            }

            const lastPos = group.fields.reduce((p, f) => Math.max(p, f.position ?? 0), constants.numbers.position.maxPosition);
            const fieldIndex = group.fields.findIndex((f) => f.name === utils.camelcase(cf.name));
            const isLink = Boolean(cf.dataType === constants.fieldDataTypes.url && constants.data.lookup('links', cf.name));
            const field = {
              ...fieldDef,
              customField: cf,
              name: cf.name,
              entity: 'entity',
              link: isLink ? constants.data.lookup('links', cf.name) : null,
              context: isLink ? {
                ...fieldDef?.context,
                link: constants.data.lookup('links', cf.name)?.value
              } : fieldDef?.context,
              variant: fieldDef?.variant ?? (isLink ? 'links' : 'detail'),
              options: fieldDef?.options ?? (isLink ? 'linkSuggestions' : null),
              auth: {
                read: utils.createAuth({attribute:`collection.entity.field.${utils.camelcase(cf.name)}.read`}),
                update: utils.createAuth({attribute:`collection.entity.field.${utils.camelcase(cf.name)}.update`})
              },
              position: +(cf.position ?? fieldDef?.position ?? (lastPos + 1)),
              required: fieldDef?.required ?? false
            };

            if (fieldIndex !== -1) {
              group.fields[fieldIndex] = {
                ...group.fields[fieldIndex],
                ...field
              }
            } else {
              group.fields.push(field);
            }
          }
        });

      return groups;
    } else {
      return null;
    }
  }, [tagGroups, customFields, view]);
}

export function useCollectionEntityColumns (view, collection, customFields, questionnaireQuestions) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    if (customFields) {
      let columns = [];
      const placeholders = ['collectionTags', 'questionnaire'];

      const hasQuestionnaire = questionnaireQuestions?.length > 0;
      const presetDefs = constants.presetDefinition.lookup('collection.entity', view)
        .filter((preset) => hasQuestionnaire || !preset.has?.includes('questionnaire'));

      Object.keys(constants.collection.entity.columnDefinition).forEach((k) => {
        if (!utils.isFunction(constants.collection.entity.columnDefinition[k]) && !placeholders.includes(k)) {
          const groupDef = constants.groupDefinition.lookup('collection.entity', view, k, true, utils.mergeObjects);
          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
            .find((k1) => k1 === k)];
          const columnDef = constants.columnDefinition.lookup('collection.entity', view, k, utils.mergeObjects);

          const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
          const lastGroupPos = Math.floor(lastPos / constants.numbers.position.groupGap);
          const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
          const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (+p + 1) : p, groupDef?.name ? 0 : null);
          const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
            ((firstPosInGroup ?? 0) === 0 && lastGroupPos > 0) ? (lastGroupPos + 1) :
              ((firstPosInGroup > 0) ? (Math.floor((firstPosInGroup - 1) / constants.numbers.position.groupGap)) :
                (firstPosInGroup < 0 ? (Math.floor((firstPosInGroup + 1) / constants.numbers.position.groupGap)) :
                  (lastGroupPos + 1)))
          )) * constants.numbers.position.groupGap;
          const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (
            groupPos > 0 ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) :
              (groupPos - (fieldDef?.position ?? (lastPosInGroup + 1)))
          ) : (lastPos + 1));

          if (!columns.find((c) => c.name === k)) {
            columns.push({
              ...fieldDef,
              ...columnDef,
              name: k,
              id: columnDef.sortingKey ?? k,
              entity: 'entity',
              hidden: columnDef?.hidden,
              header: columnDef?.header ?? fieldDef?.label,
              group: columnDef.group ?? groupDef,
              presets: columnDef.presets ?? presetDefs,
              position: columnDef.position ?? fieldPos,
              fieldPosition: columnDef.group ? (columnDef.position ?? fieldPos) : (fieldDef?.position ?? lastPosInGroup),
              auth: {
                read: utils.createAuth({attribute:`collection.entity.column.${k}.read`}),
                update: utils.createAuth({attribute:`collection.entity.column.${k}.update`})
              }
            });
          }
        }
      });

      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);
        return pos ? pos : (
          (+a.clientId - +b.clientId) ? (+a.clientId - +b.clientId) : (
            (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '')?.toLowerCase()) ?
              (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '').toLowerCase()) : (
                a.label?.localeCompare(b.label) ? a.label?.localeCompare(b.label) : +b.fieldId - +a.fieldId
              )
          )
        );
      })
        .forEach((cf) => {
          let groupDef = constants.groupDefinition.lookup('collection.entity', view, cf.name, true, utils.mergeObjects) ??
            constants.groupDefinition.lookup('collection.entity', view, cf.groupName, false, utils.mergeObjects);
          // custom fields always have groups
          groupDef = {
            ...groupDef,
            name: groupDef?.name ?? utils.camelcase(cf.groupName),
            title: groupDef?.title ?? utils.upperFirst(cf.groupName)
          };

          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
            .find((k) => k === utils.camelcase(cf.name))];
          const columnDef = constants.columnDefinition.lookup('collection.entity', view, cf.name, utils.mergeObjects);

          const lastGroupPos = Math.floor(columns.reduce((p, c) => Math.max(p, c.position), constants.numbers.position.maxPosition) / constants.numbers.position.groupGap);
          const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
          const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (+p + 1) : p, groupDef?.name ? constants.numbers.position.maxPosition : null);
          const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
            ((firstPosInGroup ?? 0) === 0 && lastGroupPos > 0) ? (lastGroupPos + 1) :
              ((firstPosInGroup > 0) ? (Math.floor((firstPosInGroup - 1) / constants.numbers.position.groupGap)) :
                (firstPosInGroup < 0 ? (Math.floor((firstPosInGroup + 1) / constants.numbers.position.groupGap)) :
                  (lastGroupPos + 1)))
          )) * constants.numbers.position.groupGap;

          let columnIndex = columns.findIndex((c) => c.name === utils.camelcase(cf.name));
          if (columnIndex === -1) {
            columns.push({
              ...fieldDef,
              ...columnDef,
              entity: 'entity'
            });
            columnIndex = columns.length - 1;
          }
          const columnPos = (
            utils.isDefined(cf.position ?? fieldDef?.position ?? lastPosInGroup) ?
              (groupPos > 0 ? (groupPos + (cf.position ?? fieldDef?.position ?? (lastPosInGroup + 1))) :
                (groupPos - (cf.position ?? fieldDef?.position ?? (lastPosInGroup + 1)))) :
              (columns[columnIndex].position ?? (lastGroupPos + 1))
          )

          const isBoolean = Boolean(cf.renderer === constants.fieldRenderers.boolean);
          const isLink = Boolean(cf.dataType === constants.fieldDataTypes.url && constants.data.lookup('links', cf.name));
          columns[columnIndex] = {
            ...columns[columnIndex],
            id: columns[columnIndex].sortingKey ?? cf.name,
            name: cf.name,
            group: columns[columnIndex].group ?? groupDef,
            presets: columns[columnIndex].presets ?? presetDefs,
            header: columns[columnIndex].header ?? cf.label,
            customField: cf,
            link: isLink ? constants.data.lookup('links', cf.name) : null,
            type: columns[columnIndex].type ?? (isBoolean ? constants.formFieldTypes.list : null),
            conversion: columns[columnIndex].conversion ?? (isBoolean ? constants.formFieldConversionTypes.value : null),
            context: isLink ? {
              ...fieldDef?.context,
              link: constants.data.lookup('links', cf.name)?.value
            } : fieldDef?.context,
            options: columns[columnIndex].options ?? (
              isLink ? 'linkSuggestions' : (isBoolean ? 'toggleYesNoInverted' : null)
            ),
            auth: {
              read: utils.createAuth({attribute: `collection.entity.column.${utils.camelcase(cf.name)}.read`}),
              update: utils.createAuth({attribute: `collection.entity.column.${utils.camelcase(cf.name)}.update`})
            },
            position: columnDef?.position ? columnDef.position : columnPos,
            fieldPosition: columns[columnIndex].fieldPosition ?? fieldDef?.position ?? lastPosInGroup,
            visible: columns[columnIndex].visible ?? false,
            sortable: columnDef?.sortable ?? true,
            required: columnDef?.required ?? false,
            FormFieldProps: {
              ...columns[columnIndex]?.FormFieldProps,
              showTooltip: ['markdown', 'textarea'].includes(cf.renderer)
            }
          };
        });

      const hasPoints = tagGroups?.some((tg) => tg.hasPoints);
      columns = columns.filter((c) => hasPoints || !c.has?.includes('points'));

      tagGroups?.forEach((tg) => {
        const columnDef = constants.columnDefinition.lookup('collection.entity', view, 'collectionTags', utils.mergeObjects);
        const groupDef = constants.groupDefinition.lookup('collection.entity', view, 'collectionTags', false, utils.mergeObjects);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? constants.numbers.position.maxPosition) * constants.numbers.position.groupGap;
        const lastPos = Object.keys(groupDef.fields || {}).reduce((p, k) => Math.max(p, groupDef.fields[k].tablePosition ?? groupDef.fields[k].position), 0);

        columns.push({
          ...columnDef,
          id: `dropdown_${tg.groupId}`, // sortingKey
          name: `tagGroup${tg.groupId}`,
          entity: 'entity',
          group: groupDef ? {
            name: 'collectionTags',
            ...groupDef
          } : null,
          presets: presetDefs,
          sortingKey: `dropdown_${tg.groupId}`,
          header: tg.name,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          position: lastPos + groupPos + (tg.pos || 1),
          fieldPosition: tg.pos,
          required: false,
          visible: columnDef?.visible ?? false,
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.column.collectionTags.read', meta: {tagGroup: tg}}),
            update: utils.createAuth({attribute: 'collection.entity.column.collectionTags.update', meta: {tagGroup: tg}}),
          },
          FormFieldProps: {
            ChipListProps: {
              variant: 'compact'
            }
          }
        });
      });

      questionnaireQuestions?.forEach((questionnaireQuestion, idx) => {
        const columnDef = constants.columnDefinition.lookup('collection.entity', view, 'questionnaire', utils.mergeObjects);
        const groupDef = constants.groupDefinition.lookup('collection.entity', view, 'questionnaire', false, utils.mergeObjects);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? constants.numbers.position.maxPosition) * constants.numbers.position.groupGap;
        const lastPos = Object.keys(groupDef.fields || {}).reduce((p, k) => Math.max(p, groupDef.fields[k].tablePosition ?? groupDef.fields[k].position), 0);

        columns.push({
          id: `answer_personal_${questionnaireQuestion.questionId}`, // sortingKey
          name: `personalAnswer${questionnaireQuestion.questionId}`,
          entity: 'entity',
          type: constants.formFieldTypes.text,
          group: groupDef ? {
            name: 'questionnaire',
            ...groupDef
          } : null,
          presets: presetDefs,
          sortingKey: `answer_personal_${questionnaireQuestion.questionId}`,
          header: questionnaireQuestion.question.question + ' (personal)',
          questionnaireQuestion: questionnaireQuestion,
          position: lastPos + groupPos + (idx * 2),
          visible: columnDef?.visible ?? false,
          required: false,
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.column.personalAnswer.read', meta: {questionnaireQuestion}}),
            update: utils.createAuth({attribute: 'collection.entity.column.personalAnswer.update', meta: {questionnaireQuestion}}),
          }
        });

        columns.push({
          id: `answer_overall_${questionnaireQuestion.questionId}`, // sortingKey
          name: `overallAnswer${questionnaireQuestion.questionId}`,
          entity: 'entity',
          type: constants.formFieldTypes.text,
          group: groupDef ? {
            name: 'questionnaire',
            ...groupDef
          } : null,
          presets: presetDefs,
          sortingKey: `answer_overall_${questionnaireQuestion.questionId}`,
          header: questionnaireQuestion.question.question + ' (overall)',
          questionnaireQuestion: questionnaireQuestion,
          position: lastPos + groupPos + (idx * 2) + 1,
          visible: columnDef?.visible ?? false,
          required: false,
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.column.overallAnswer.read', meta: {questionnaireQuestion}}),
            update: utils.createAuth({attribute: 'collection.entity.column.overallAnswer.update', meta: {questionnaireQuestion}}),
          }
        });
      });

      return columns;
    } else {
      return null;
    }
  }, [tagGroups, customFields, questionnaireQuestions, view]);
}

export function useCollectionEntityFilterGroups (view, collection, customFields) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    const filterGroups = [];
    if (tagGroups && customFields) {
      Object.keys(constants.collection.entity.filterGroupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.collection.entity.filterGroupDefinition[k])) {
          const groupDef = constants.filterGroupDefinition.lookup('collection.entity', view, k, false, utils.mergeObjects);
          const filters = utils.isFunction(groupDef.filters) ? groupDef.filters(view) : groupDef.filters;
          const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
          filterGroups.push({
            ...groupDef,
            name: k,
            position: (groupDef.position ?? (lastPos + 1)),
            filters: Object.keys(filters ?? {}).map((k, idx) => {
              const f = filters[k];
              return {
                ...f,
                id: f.filterKey ?? k,
                name: k,
                entity: 'entity',
                position: f.position ?? (idx + 1),
                auth: {
                  read: utils.createAuth({attribute: `collection.entity.filter.${k}.read`}),
                  update: utils.createAuth({attribute: `collection.entity.filter.${k}.update`}),
                }
              }
            })
          });
        }
      });

      let groupDef = constants.filterGroupDefinition.lookup('collection.entity', view, 'customFields', true, utils.mergeObjects);
      let group = filterGroups.find((g) => g.name === groupDef.name);
      let filter = group.filters.find((f) => f.name === 'customFields');
      group.filters = group.filters.filter((f) => f.name !== 'customFields');

      let filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);
        return pos ? pos : (
          (+a.clientId - +b.clientId) ? (+a.clientId - +b.clientId) : (
            (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '')?.toLowerCase()) ?
              (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '').toLowerCase()) : (
                a.label?.localeCompare(b.label) ? a.label?.localeCompare(b.label) : +b.fieldId - +a.fieldId
              )
          )
        );
      })?.forEach((cf) => {
        const typeToFilterProps = () => {
          if (cf.renderer === constants.fieldRenderers.monetary) {
            return {
              monetary: true,
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'moneyRange',
              FormFieldProps: {
                multiple: true
              }
            }
          } else if (cf.renderer === constants.fieldRenderers.numeric) {
            return {
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'mediumRange',
              FormFieldProps: {
                multiple: true
              }
            }
          } else if (cf.renderer === constants.fieldRenderers.date) {
            return {
              placeholder: 'Search month',
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'monthsFrom',
              FormFieldProps: {
                showSearch: true,
                multiple: true
              }
            }
          } else if (cf.renderer === constants.fieldRenderers.boolean) {
            return {
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'toggleYesNo',
              FormFieldProps: {
                multiple: true
              }
            }
          } else {
            return {
              type: constants.formFieldTypes.text
            }
          }
        }

        if (constants.data.lookup('customFieldRendererTypes', cf.renderer)) {
          filterPos += 1;
          group.filters.push({
            ...filter,
            id: cf.name, // filterKey
            name: cf.name,
            label: cf.label,
            filterKey: cf.name,
            customField: cf,
            description: '',
            position: +(cf.position ?? filterPos),
            auth: {
              read: utils.createAuth({attribute: 'collection.entity.filter.customFields.read'}),
              update: utils.createAuth({attribute: 'collection.entity.filter.customFields.update'}),
            },
            ...(typeToFilterProps())
          });
        }
      });

      // lookup group for name
      groupDef = constants.filterGroupDefinition.lookup('collection.entity', view, 'collectionTags', true, utils.mergeObjects);
      group = filterGroups.find((g) => g.name === groupDef.name);
      filter = group.filters.find((f) => f.name === 'collectionTags');
      group.filters = group.filters.filter((f) => f.name !== 'collectionTags');

      filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      tagGroups?.forEach((tg) => {
        group.filters.push({
          ...filter,
          id: `group_${tg.groupId}`, // filterKey
          name: `tagGroup${tg.groupId}`,
          label: tg.name,
          validation: `${constants.formFieldValidationTypes.list}(${constants.formFieldValidationTypes.int})`,
          filterKey: `group_${tg.groupId}`,
          type: constants.formFieldTypes.list,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          position: (filterPos + tg.pos),
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.filter.collectionTags.read'}),
            update: utils.createAuth({attribute: 'collection.entity.filter.collectionTags.update'}),
          },
          FormFieldProps: {
            multiple: true
          }
        });
      });

      return filterGroups;
    } else {
      return null;
    }
  }, [tagGroups, customFields, view]);
}

export function useCollectionEntitySections (view, collection, questionnaireQuestions) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.collection.entity.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.collection.entity.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('collection.entity', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `collection.entity.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `collection.entity.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            ...cardDef,
            auth: {
              read: utils.createAuth({attribute: `collection.entity.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `collection.entity.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    if (questionnaireQuestions) {
      const questionnaireSection = sections.find((s) => s.name === 'questionnaire');
      questionnaireSection.cards = questionnaireQuestions.map((questionnaireQuestion, idx) => {
        return {
          ...questionnaireQuestion,
          position: idx,
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.section.personalAnswer.read', meta: {questionnaireQuestion}}),
            update: utils.createAuth({attribute: 'collection.entity.section.personalAnswer.update', meta: {questionnaireQuestion}}),
          }
        }
      });
    }

    return sections;
  }, [view, questionnaireQuestions]);
}

export function useCollectionEntityGraphs (view) {
  return useMemo(() => {
    const graphs = [];
    Object.keys(constants.collection.entity.graphDefinition).forEach((k) => {
      if (!utils.isFunction(constants.collection.entity.graphDefinition[k])) {
        const graphDef = constants.graphDefinition.lookup('collection.entity', view, k, utils.mergeObjects);
        const lastPos = graphs.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        graphs.push({
          ...graphDef,
          name: k,
          position: (graphDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `collection.entity.graph.${graphDef.name}.read`}),
            update: utils.createAuth({attribute: `collection.entity.graph.${graphDef.name}.update`})
          }
        });
      }
    });

    return graphs;
  }, [view]);
}

export function useCollectionEntitySettings (collectionId = null) {
  const client = useAuthClient();
  const authorize = useAuthorize();

  const [defaultFieldData, defaultFilterGroups] = useEntitySettings();

  const collectionEntityProfileCallbacks = useCollectionEntityCallbacks();

  const collectionCustomFields = useCollectionCustomFieldCache({clientId: client?.clientId, collectionId: collectionId, addLinks: false},
    {enabled: client?.clientId >= 0 && collectionId > 0});
  const collectionCustomFieldsCache = (collectionCustomFields.data?.data ?? collectionCustomFields.data);

  const collection = useCollectionGet({collectionId: collectionId},
    {enabled: Boolean(collectionId)});

  const collectionFieldData = useMemo(() => ({
    customFields: collectionCustomFieldsCache ?? [],
    tagGroups: collection?.data?.tagGroups,
    callbacks: collectionEntityProfileCallbacks
  }), [collectionEntityProfileCallbacks, collectionCustomFieldsCache, collection?.data?.tagGroups]);

  const fieldData = useMergeFieldData(defaultFieldData, collectionFieldData);

  const collectionEntityFilterGroups = useCollectionEntityFilterGroups(null, collection?.data, collectionCustomFieldsCache);
  const filterGroupsMerged = useMergeFilterGroupDefinitions(defaultFilterGroups, collectionEntityFilterGroups);

  const filterGroups = useMemo(() => {
    if (filterGroupsMerged?.length > 0) {
      return filterGroupsMerged
        .filter((filterGroupDef) => !filterGroupDef.hidden && authorize(filterGroupDef.auth?.read ?? {}))
        .map((filterGroupDef) => {
          filterGroupDef.filters = (filterGroupDef.filters ?? [])
            .filter((f) => !f.hidden && authorize(f.auth?.read ?? {}));
          return filterGroupDef;
        });
    } else {
      return null;
    }
  }, [authorize, filterGroupsMerged]);

  return [fieldData, filterGroups, collection?.data, fieldData.customFields, client];
}

export function useCollectionEntityScore (collection, entity) {
  const isUniverse = utils.isDefined(collection?.universeClientId);
  const prefix = isUniverse ? 'client' : 'collection';
  const positivePotential = collection?.scoringPotential?.positivePotentialAdvanced ?? 0;

  const totalPoints = useMemo(() => {
    if (utils.isDefined(entity?.[prefix + 'AnalysisPointsAdvanced'])) {
      return +entity?.[prefix + 'AnalysisPointsAdvanced'];
    } else {
      return 0;
    }
  }, [prefix, entity]);

  const score = (positivePotential > 0 && totalPoints >= 0) ? (totalPoints / positivePotential) * 100 : 0;

  return score ?? 0;
}

export function useCollectionEntityScoreBreakDown (collection, entity) {
  const isUniverse = utils.isDefined(collection?.universeClientId);
  const positivePotential = collection?.scoringPotential?.positivePotentialAdvanced ?? 0;
  const tagGroups = collection?.tagGroups;
  const questions = isUniverse ? entity?.clientQuestions : entity?.collectionQuestions;

  const breakdown = useMemo(() => {
    if (questions && tagGroups) {
      return tagGroups.map((tg) => {
        let tgQs = questions.filter((q) => {
          return q.questionType === constants.collection.questionTypes.advanced &&
            q.analyserType === constants.collection.analyserTypes.collectionTagAnalyser &&
            +q.tagGroup === +tg.groupId;
        });

        return {
          questions: tgQs,
          tagGroup: tg
        }
      });
    } else {
      return [];
    }
  }, [questions, tagGroups]);

  return [breakdown, positivePotential];
}
