import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFieldInlineEdit from 'components/organisms/InlineEdits/FieldInlineEdit/FieldInlineEdit.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {withMemo} from 'helpers/wrapper';
import constants from 'helpers/constants';
import utils from 'helpers/utils';

const FieldInlineEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    fieldData,
    onSubmit,
    onChange,
    onBusy,
    ...innerProps
  } = useComponentProps(props, 'FieldInlineEdit');

  const fieldsMemo = useMemo(() => {
    return fields.map((field) => {
      const formField = utils.fields2FormFields([field], fieldData)[0];
      return {
        ...field,
        debounce: constants.formFieldTypes.textTypes.multiline.includes(formField.type) ? constants.debounce.multiline : true,
        FormFieldProps: utils.mergeObjects(formField.FormFieldProps, {
          size: 'smaller',
          variant: !(fields.length > 1) ? 'inlineLabel' : 'staticLabel',
          hiddenLabel: !(fields.length > 1),
          fullWidth: true,
        }, true)
      };
    });
  }, [fields, fieldData]);

  return <StyledFieldInlineEdit ref={ref} {...innerProps}>
    <InlineForm fields={fieldsMemo}
                autoTouch={true}
                fieldData={fieldData}
                onChange={onChange}
                onSubmit={onSubmit}
                onBusy={onBusy}/>
  </StyledFieldInlineEdit>
}));

FieldInlineEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onBusy: PropTypes.func
};

FieldInlineEdit.defaultProps = {};

export default FieldInlineEdit;
