import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTableBar from 'components/organisms/Bars/TableBar/TableBar.styles';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ViewColumnSharp from '@mui/icons-material/ViewColumnSharp';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import FilterList from '@mui/icons-material/FilterList';
import Badge from 'components/atoms/Badges/Badge/Badge';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Save from '@mui/icons-material/Save';
import useMediaQuery from '@mui/material/useMediaQuery';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import Box from 'components/atoms/Layout/Box/Box';
import Close from '@mui/icons-material/Close';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import WizardDialog from 'components/organisms/Dialogs/WizardDialog/WizardDialog';
import Toc from '@mui/icons-material/Toc';

const TableBar = React.forwardRef((props, ref) => {
  const {
    searchField,
    analyseAction,
    indexAction,
    filterAction,
    columnAction,
    saveAction,
    hideSearch,
    hideWizard,
    hideAnalyse,
    hideIndex,
    hideFilter,
    hideColumn,
    hideSave,
    ...innerProps
  } = useComponentProps(props, 'TableBar');

  const searchFieldRef = useRef(null);
  const tableProvider = useTable();

  const dialogControl = useDialogControl();

  const [wizardActive, setWizardActive] = useState(null);

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const filterCount = utils.filterCount(tableProvider.listState.filter);

  const wizard = useMemo(() => {
    const filter = (tableProvider.listState.filter ?? []).find((f) => f.id === 'wizard');
    return filter ? (utils.toArray(filter.value, true)?.[0] ?? '') : '';
  }, [tableProvider.listState.filter]);

  const wizardMode = useMemo(() => {
    if (!hideWizard && !hideSearch) {
      if (tableProvider.listState?.active || Boolean(wizard)) {
        return utils.isDefined(wizardActive) ? wizardActive :
          !Boolean(tableProvider.listState?.search) && Boolean(wizard);
      }
    } else {
      return null;
    }
  }, [hideWizard, hideSearch, tableProvider.listState?.active, tableProvider.listState?.search, wizard, wizardActive]);

  const setSearchEvent = useEffectEvent(tableProvider.listState.setSearch);
  const setFilterEvent = useEffectEvent(tableProvider.listState.setFilter);
  const doChangeSearch = useCallback((wizardActive, search, wizard) => {
    setSearchEvent?.(wizardActive ? '' : search);

    if (wizardActive && !utils.isEmpty(wizard)) {
      setFilterEvent?.((current) => utils.applyFilters(current, [{id: 'wizard', value: wizard}], 'wizard'));
    } else {
      setFilterEvent?.((current) => utils.applyFilters(current, [{id: 'wizard', value: null}], 'wizard'));
    }

    setWizardActive(wizardActive);
  }, [setSearchEvent, setFilterEvent]);

  const handleSearch = (field, value) => {
    doChangeSearch(wizardActive, wizardActive ? '' : value, wizardActive ? value : '');
  }

  useEffect(() => {
    if (!hideWizard && !hideSearch) {
      if (utils.isDefined(wizardMode)) {
        doChangeSearch(wizardMode, tableProvider.listState?.search, wizard);
      }
    }
  }, [hideWizard, hideSearch, wizardMode, tableProvider.listState?.search, wizard, doChangeSearch]);

  const showWizardDialog = useCallback((placeholder) => {
    const handleSubmit = (text) => {
      doChangeSearch(true, '', text);
    }

    dialogControl.show(<WizardDialog text={tableProvider.listState?.search || wizard}
                                     placeholder={placeholder}
                                     onSubmit={handleSubmit} />, true);
  }, [dialogControl, tableProvider.listState?.search, wizard, doChangeSearch]);

  const searchFields = useMemo(() => {
    const getPlaceholder = (wizardActive) => {
      return !searchField?.placeholder ? null :
        (wizardActive ? `Type a description of ${searchField?.placeholder.split(' ').slice(-2).join(' ')}` : searchField?.placeholder)
    };

    const initialSearch = (wizardActive ? wizard : tableProvider.listState?.search)?.replace(/(\n)+/g, ' ');

    return [{
      name: 'search',
      label: !mdDown ? (wizardActive ? 'Wizard' : 'Search') : null,
      inlineLabel: wizardActive ? 'Wizard text' : 'Search text',
      placeholder: getPlaceholder(wizardActive),
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      initial: initialSearch,
      debounce: constants.debounce.search,
      FormFieldProps: {
        ref: searchFieldRef,
        hiddenLabel: true,
        size: 'smaller',
        radius: 'round',
        onClick: (e) => {
          if (!e.defaultPrevented && wizardActive) {
            showWizardDialog(getPlaceholder(true));

            e.preventDefault();
          }
        },
        onKeyDown: (e) => {
          if (!e.defaultPrevented && wizardActive) {
            showWizardDialog(getPlaceholder(true));

            e.preventDefault();
          }
        }
      },
      prefix: <Icon icon={Search} />,
      postfix: <Box className="TableBar-search-adornment">
        {(initialSearch?.length > 0) ? <ActionIconButton action={{
          icon: <Icon icon={Close}/>,
          onClick: (e) => {
            doChangeSearch(false, '', '');
            searchFieldRef.current?.focus();
            e.preventDefault();
          }
        }}/> : null}
        {!hideWizard ? <ActionIconButton className="TableBar-search-adornment-wizard"
                                         size="smaller"
                                         density="sparse"
                                         plainBorder={!wizardActive}
                                         color={!wizardActive ? 'secondary' : 'primary'}
                                         variant={!wizardActive ? 'outlined' : 'contained'}
                                         action={{
                                           icon: AutoAwesome,
                                           tooltip: 'Wizard',
                                           onClick: (e) => {
                                             if (!wizardActive) {
                                               showWizardDialog(getPlaceholder(true));
                                             } else {
                                               doChangeSearch(false, wizard, '');
                                             }

                                             e.preventDefault();
                                           }
                                         }}
                                         IconProps={{
                                           size: 'smallest'
                                         }} /> : null}
      </Box>,
      ...utils.filterObject(searchField, 'placeholder')
    }];
  }, [searchField, hideWizard, mdDown, tableProvider.listState?.search, wizard, wizardActive, showWizardDialog, doChangeSearch]);

  const toggleColumnsEvent = useEffectEvent(tableProvider.toggleColumns);
  const columnActionMemo = useMemo(() => ({
    onClick: () => {
      toggleColumnsEvent?.();
    },
    label: !mdDown ? 'Columns' : null,
    tooltip: mdDown ? 'Columns' : null,
    icon: ViewColumnSharp,
    ...columnAction,
    ActionButtonProps: {
      variant: 'outlined',
      plainBorder: true,
      color: 'inherit',
      size: 'medium',
      ...columnAction?.ActionButtonProps
    },
    ActionIconButtonProps: {
      ...columnAction?.ActionIconButtonProps
    }
  }), [columnAction, mdDown, toggleColumnsEvent]);

  const analyseActionMemo = useMemo(() => ({
    label: !mdDown ? 'Analyse' : null,
    tooltip: mdDown ? 'Analyse' : null,
    icon: AutoAwesome,
    ...analyseAction,
    ActionButtonProps: {
      color: 'secondary',
      variant: 'outlined',
      plainBorder: true,
      radius: 'round',
      ...analyseAction?.ActionButtonProps
    },
    ActionIconButtonProps: {
      ...analyseAction?.ActionIconButtonProps
    },
    ButtonProps: {
      disabled: !(tableProvider.list?.meta?.resultsCount > 0),
      ...analyseAction?.ButtonProps
    },
    IconButtonProps: {
      disabled: !(tableProvider.list?.meta?.resultsCount > 0),
      ...analyseAction?.IconButtonProps
    }
  }), [analyseAction, mdDown, tableProvider.list?.meta?.resultsCount]);

  const toggleIndexEvent = useEffectEvent(tableProvider.toggleIndex);
  const indexActionMemo = useMemo(() => ({
    onClick: () => {
      toggleIndexEvent?.();
    },
    label: !mdDown ? 'Index' : null,
    tooltip: mdDown ? 'Index' : null,
    icon: Toc,
    ...indexAction,
    ActionButtonProps: {
      color: 'secondary',
      variant: 'outlined',
      plainBorder: true,
      radius: 'round',
      ...indexAction?.ActionButtonProps
    },
    ActionIconButtonProps: {
      ...indexAction?.ActionIconButtonProps
    }
  }), [indexAction, mdDown, toggleIndexEvent]);

  const toggleFiltersEvent = useEffectEvent(tableProvider.toggleFilters);
  const filterActionMemo = useMemo(() => ({
    onClick: () => {
      toggleFiltersEvent?.();
    },
    label: !mdDown ? 'Filters' : null,
    tooltip: mdDown ? 'Filters' : null,
    icon: FilterList,
    badge: filterCount > 0 ? <Badge badgeContent={<Tooltip title={'Clear'}>
      <Span onClick={(e) => {
              setFilterEvent?.(wizard ? [{id: 'wizard', value: wizard}] : []);
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseOver={() => console.log('hover')}
            onMouseDown={(e) => e.stopPropagation()}>
        {filterCount}
      </Span>
    </Tooltip>} clickable={true} color="error"/> : null,
    ...filterAction,
    ActionButtonProps: {
      color: 'secondary',
      variant: 'outlined',
      plainBorder: true,
      radius: 'round',
      ...filterAction?.ActionButtonProps
    },
    ActionIconButtonProps: {
      ...filterAction?.ActionIconButtonProps
    }
  }), [filterAction, filterCount, wizard, setFilterEvent, mdDown, toggleFiltersEvent]);

  const saveActionMemo = useMemo(() => ({
    label: !mdDown ? 'Save' : null,
    tooltip: mdDown ? 'Save' : null,
    icon: Save,
    ...saveAction,
    ActionButtonProps: {
      color: 'primary',
      variant: 'contained',
      radius: 'round',
      ...saveAction?.ActionButtonProps
    },
    ActionIconButtonProps: {
      color: 'primary',
      variant: 'contained',
      ...saveAction?.ActionIconButtonProps
    }
  }), [saveAction, mdDown]);

  const resultsCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.title = innerProps.title ?? `${resultsCount} result${(tableProvider.list?.meta?.resultsCount ?? 0) === 1 ? '' : 's'}`;
  innerProps.rightFields = innerProps.rightFields ?? (
    hideSearch ? null : searchFields
  );
  innerProps.onRightChange = innerProps.onRightChange ?? (
    hideSearch ? null : handleSearch
  );
  innerProps.rightActions = innerProps.rightActions ?? [
    hideSave ? null : saveActionMemo,
    hideAnalyse ? null : analyseActionMemo,
    hideFilter ? null : filterActionMemo,
    hideColumn ? null : columnActionMemo,
    hideIndex ? null : indexActionMemo
  ].filter((_) => (_));

  innerProps.isLoading = innerProps.isLoading ?? (tableProvider.isLoading() || !utils.isDefined(tableProvider.list?.meta?.resultsCount));

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTableBar ref={ref} {...innerProps}/>
});

TableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  searchField: PropTypes.object,
  indexAction: PropTypes.object,
  filterAction: PropTypes.object,
  columnAction: PropTypes.object,
  saveAction: PropTypes.object,
  hideSearch: PropTypes.bool,
  hideWizard: PropTypes.bool,
  hideAnalyse: PropTypes.bool,
  hideIndex: PropTypes.bool,
  hideFilter: PropTypes.bool,
  hideColumn: PropTypes.bool,
  hideSave: PropTypes.bool
};

TableBar.defaultProps = {
  hideWizard: true,
  hideSave: true,
  hideIndex: true
};

export default TableBar;
